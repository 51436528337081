import React, { Component } from "react";
import { Col, Row, Form, Button, Card, FormControl, OverlayTrigger, Tooltip, DropdownButton, Dropdown, ButtonGroup, Tabs, Tab } from "react-bootstrap";
import { generateHash } from "../Student/student.commons"
import "./pending.css"
import { X } from 'react-bootstrap-icons';

class StagingPending extends Component {
    constructor() {
        super();
        this.state = {
            staging: null,
            isLoaded: false,
            skillSubject: "",
            skillEmail: "",
            preferenceSubject: "",
            preferenceEmail: "",
            studentMailSelected: [],
            addStudent: "",
            addProject: "",
            interval: null
        };
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_API_URL + "/stagingById/" + this.props.match.params.id, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        staging: result,
                        isLoaded: true
                    });
                    this.createEmailText()
                    let studentMailSelected = this.state.staging.selectedStudents.map(student => {
                        return true
                    })
                    this.setState({ studentMailSelected })
                    let interval = setInterval(() => {
                        this.refreshStaging()
                    }, 10000);
                    this.setState({ interval })
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }


    createEmailText() {
        let currentDay = new Date()
        currentDay.setDate(currentDay.getDate() + 3)
        this.setState({
            skillSubject: this.state.staging.stagingName + " - " + (this.state.staging.stagingType !== "TASKFORCE & BUDDIES" ? "Skill" : "Skill & Preference") + " Survey",
            preferenceSubject: this.state.staging.stagingName + " - Preference Survey",
            skillEmail: "Dear Students,\n\nplease fill out the " + (this.state.staging.stagingType !== "TASKFORCE & BUDDIES" ? "Skill" : "Skill & Preference") + " survey at %LINK% until " + currentDay.getFullYear() + "." + (currentDay.getMonth() + 1) + "." + currentDay.getDate() + ".\n",
            preferenceEmail: "Dear Students,\n\nplease fill out the preference survey at %LINK%."
        })
    }

    refreshStaging() {
        fetch(process.env.REACT_APP_API_URL + "/stagingById/" + this.props.match.params.id, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        staging: result,
                        isLoaded: true
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    updateStaging() {
        fetch(process.env.REACT_APP_API_URL + '/staging', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token'),
            },
            body: JSON.stringify(this.state.staging)
        }).then((result) => {
            return result.json()
        },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    }

    deleteStudent(index) {
        let staging = this.state.staging
        staging.students = staging.students.reduce((result, element) => {
            if (element.cdtmEmail !== staging.selectedStudents[index].studentEmail) {
                result.push(element)
            }
            return result
        }, [])
        staging.selectedStudents.splice(index, 1)
        let studentMailSelected = this.state.studentMailSelected
        studentMailSelected.splice(index, 1)
        this.setState({ staging, studentMailSelected })
        this.updateStaging()
    }

    addStudent() {
        if (!this.state.staging.selectedStudents.find(student => { return student.studentEmail === this.state.addStudent })) {
            let staging = this.state.staging
            staging.selectedStudents.push({
                preferenceSurveyStatus: "NONE",
                skillSurveyStatus: "NONE",
                studentEmail: this.state.addStudent
            })
            let studentMailSelected = this.state.studentMailSelected
            studentMailSelected.push(true)
            this.setState({ staging, studentMailSelected, addStudent: "" })
            this.updateStaging()
        }
    }

    addProject() {
        if (!this.state.staging.projects.find(project => { return project.projectName === this.state.addProject })) {
            let staging = this.state.staging
            staging.projects.push({
                projectName: this.state.addProject,
            })
            let preference = this.state.staging.restrictions.reduce((result, element) => {
                if (element.restrictionType === "PREFERENCE") {
                    result = element
                }
                return result
            }, null)
            preference.restriction.options.push(this.state.addProject)
            this.setState({ staging, addProject: "" })
            this.updateStaging()
        }
    }

    deleteProject(index) {
        let staging = this.state.staging
        staging.projects.splice(index, 1)
        let preference = this.state.staging.restrictions.reduce((result, element) => {
            if (element.restrictionType === "PREFERENCE") {
                result = element
            }
            return result
        }, null)
        preference.restriction.options.splice(index, 1)
        this.setState({ staging })
        this.updateStaging()
    }

    sendEmails(emailType) {
        let studentsToSend = this.state.studentMailSelected.reduce((result, isSelected, index) => {
            if (isSelected) {
                result.push(this.state.staging.selectedStudents[index])
            }
            return result
        }, [])
        let emails = studentsToSend.map(student => {
            if (emailType === "SKILL") {
                return { subject: this.state.skillSubject, email: this.state.skillEmail.replace("%LINK%", window.location.href.replace(window.location.pathname, '/survey/skill/' + this.state.staging._id + '/' + generateHash(student.studentEmail))) }
            } else if (emailType === "PREFERENCE") {
                return { subject: this.state.preferenceSubject, email: this.state.preferenceEmail.replace("%LINK%", window.location.href.replace(window.location.pathname, '/survey/preference/' + this.state.staging._id + '/' + generateHash(student.studentEmail))) }
            }
            return ""
        })
        fetch(process.env.REACT_APP_API_URL + '/surveyForStaging/' + this.props.match.params.id, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
            },
            body: JSON.stringify({ emailType: emailType, studentsToSend: studentsToSend, emails: emails })
        }).then((result) => {
            return result.json()
        }).then((result) => {
            this.refreshStaging()
        },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )

    }


    render() {
        return (
            <div className="backgroundLightGray">
                {this.state.isLoaded &&
                    <div>
                        <h2 className="pt-2 mb-2 ml-2">{this.state.staging.stagingName}  - Participants & Surveys</h2>
                        <Row className="m-2">
                            <Col xs={12} sm={6} md={8} lg={8} xl={8} className="mt-2">
                                <Card >
                                    <h3 className="mt-3 mb-2 ml-3">Emails to Students</h3>
                                    <Col>
                                        <Tabs defaultActiveKey="skill" id="uncontrolled-tab-example">
                                            <Tab eventKey="skill" title={this.state.staging.stagingType !== "TASKFORCE & BUDDIES" ? "Skill Survey" : "Skill & Preference Survey"}>
                                                <Col xs={12} >
                                                    <h4 className="mt-3 mb-3">{this.state.staging.stagingType !== "TASKFORCE & BUDDIES" ? "Skill survey" : "Skill & Preference survey"}</h4>
                                                    <Form>
                                                        <Form.Group>
                                                            <Form.Label>Subject:</Form.Label>
                                                            <FormControl
                                                                disabled={this.state.staging.archived}
                                                                value={this.state.skillSubject}
                                                                onChange={e => {
                                                                    this.setState({ skillSubject: e.target.value })
                                                                }}
                                                                type="text"
                                                            />
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Label>Email:</Form.Label>
                                                            <FormControl
                                                                disabled={this.state.staging.archived}
                                                                value={this.state.skillEmail}
                                                                onChange={e => {
                                                                    this.setState({ skillEmail: e.target.value })
                                                                }}
                                                                as="textarea"
                                                                rows={16}
                                                            />
                                                        </Form.Group>
                                                        <Button disabled={this.state.staging.archived} onClick={e => { this.sendEmails("SKILL") }} className="mb-3 mt-2" block>{this.state.studentMailSelected.every(e => { return e }) ? "Send skill survey to all" : "Send skill survey to selected"}</Button>
                                                    </Form>
                                                </Col>
                                            </Tab>
                                            {this.state.staging.stagingType !== "TRENDSEMINAR" && this.state.staging.stagingType !== "TASKFORCE & BUDDIES" && <Tab eventKey="preference" title="Preference Survey">
                                                <Col xs={12}>
                                                    <h4 className="mt-3 mb-3">Preference survey</h4>
                                                    <Form>
                                                        <Form.Group>
                                                            <Form.Label>Subject</Form.Label>
                                                            <FormControl
                                                                disabled={this.state.staging.archived}
                                                                value={this.state.preferenceSubject}
                                                                onChange={e => {
                                                                    this.setState({ preferenceSubject: e.target.value })
                                                                }}
                                                                type="text"
                                                            />
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Label>Email:</Form.Label>
                                                            <FormControl
                                                                disabled={this.state.staging.archived}
                                                                value={this.state.preferenceEmail}
                                                                onChange={e => {
                                                                    this.setState({ preferenceEmail: e.target.value })
                                                                }}
                                                                as="textarea"
                                                                rows={16}
                                                            />
                                                        </Form.Group>
                                                    </Form>
                                                    <Button disabled={this.state.staging.archived} onClick={e => { this.sendEmails("PREFERENCE") }} className="mb-3 mt-2" block>{this.state.studentMailSelected.every(e => { return e }) ? "Send preference survey to all" : "Send skill preference to selected"}</Button>
                                                </Col>
                                            </Tab>}
                                        </Tabs>
                                    </Col>
                                </Card>
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={4} xl={4} className="mt-2">
                                <Card className="mb-2">
                                    <Row>
                                        <Col xs={9}><h4 className="mt-3 mb-2 ml-3">Student Emails</h4></Col>
                                        <Col xs={3} className="mt-3 mb-2">
                                            <DropdownButton title={"Select"} variant="light">
                                                <Dropdown.Item disabled={this.state.staging.archived} onClick={(e) => { let studentMailSelected = this.state.studentMailSelected.map(element => { return true }); this.setState({ studentMailSelected }) }}>All</Dropdown.Item>
                                                <Dropdown.Item disabled={this.state.staging.archived} onClick={(e) => { let studentMailSelected = this.state.studentMailSelected.map(element => { return false }); this.setState({ studentMailSelected }) }}>None</Dropdown.Item>
                                                <Dropdown.Item disabled={this.state.staging.archived} onClick={(e) => { let studentMailSelected = this.state.studentMailSelected.map((element, index) => { if (this.state.staging.selectedStudents[index].skillSurveyStatus === "DONE") { return false } return true }); this.setState({ studentMailSelected }) }}>{this.state.staging.stagingType !== "TASKFORCE & BUDDIES" ? "Skill" : "Skill & Preference"} Survey not answered</Dropdown.Item>
                                                <Dropdown.Item disabled={this.state.staging.archived} onClick={(e) => { let studentMailSelected = this.state.studentMailSelected.map((element, index) => { if (this.state.staging.selectedStudents[index].skillSurveyStatus === "NONE") { return true } return false }); this.setState({ studentMailSelected }) }}>{this.state.staging.stagingType !== "TASKFORCE & BUDDIES" ? "Skill" : "Skill & Preference"} Survey not sent</Dropdown.Item>
                                                {this.state.staging.stagingType !== "TRENDSEMINAR" && this.state.staging.stagingType !== "TASKFORCE & BUDDIES" && <><Dropdown.Item disabled={this.state.staging.archived} onClick={(e) => { let studentMailSelected = this.state.studentMailSelected.map((element, index) => { if (this.state.staging.selectedStudents[index].preferenceSurveyStatus === "DONE") { return false } return true }); this.setState({ studentMailSelected }) }}>Preference Survey not answered</Dropdown.Item>
                                                    <Dropdown.Item disabled={this.state.staging.archived} onClick={(e) => { let studentMailSelected = this.state.studentMailSelected.map((element, index) => { if (this.state.staging.selectedStudents[index].preferenceSurveyStatus === "NONE") { return true } return false }); this.setState({ studentMailSelected }) }}>Preference Survey not sent</Dropdown.Item></>}
                                            </DropdownButton>
                                        </Col>
                                    </Row>

                                    <div className="studentEmailList mb-2">
                                        {this.state.staging.selectedStudents.map((selectedStudent, studentIndex) =>
                                            <Col key={selectedStudent.studentEmail}>
                                                <Card className="mb-1 mt-1">
                                                    <Row className="mb-1 mt-1 ml-1">
                                                        <Col className="m-auto">
                                                            <Form >
                                                                <Form.Group controlId="formBasicCheckbox" className="m-auto">
                                                                    <Form.Check checked={this.state.studentMailSelected[studentIndex]} type="checkbox" onChange={e => { let studentMailSelected = this.state.studentMailSelected; studentMailSelected[studentIndex] = !studentMailSelected[studentIndex]; this.setState({ studentMailSelected }) }} />
                                                                </Form.Group>
                                                            </Form>
                                                        </Col>
                                                        <Col className="m-auto" xs={8}>
                                                            <a href={"mailto:" + selectedStudent.studentEmail}>{selectedStudent.studentEmail}</a>
                                                        </Col>
                                                        <Col className="m-auto ">
                                                            <OverlayTrigger
                                                                placement={'top'}
                                                                overlay={
                                                                    <Tooltip>
                                                                        <p>Indicates the status of the students {this.state.staging.stagingType !== "TASKFORCE & BUDDIES" ? "Skill" : "Skill & Preference"} survey</p>
                                                                        <p>Grey: Not sent</p>
                                                                        <p>Red: Not answered</p>
                                                                        <p>Green: Answered</p>
                                                                    </Tooltip>}
                                                            >
                                                                <b className={selectedStudent.skillSurveyStatus === "NONE" ? "answerNone" : selectedStudent.skillSurveyStatus === "DONE" ? "answerDone" : "answerPending"}>{this.state.staging.stagingType !== "TASKFORCE & BUDDIES" ? "S" : "SP"}</b>
                                                            </OverlayTrigger>
                                                            {this.state.staging.stagingType !== "TRENDSEMINAR" && this.state.staging.stagingType !== "TASKFORCE & BUDDIES" && <OverlayTrigger
                                                                placement={'top'}
                                                                overlay={
                                                                    <Tooltip>
                                                                        <p>Indicates the status of the students preference survey</p>
                                                                        <p>Grey: Not sent</p>
                                                                        <p>Red: Not answered</p>
                                                                        <p>Green: Answered</p>
                                                                    </Tooltip>}
                                                            >
                                                                <b className={selectedStudent.preferenceSurveyStatus === "NONE" ? "answerNone" : selectedStudent.preferenceSurveyStatus === "DONE" ? "answerDone" : "answerPending"}>P</b>
                                                            </OverlayTrigger>}
                                                        </Col>
                                                        <Col >
                                                            <Dropdown as={ButtonGroup}>
                                                                <Dropdown.Toggle className="dropDownButtonWhite" id="dropdown-split-basic" />
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item disabled={this.state.staging.archived} onClick={(e) => { this.deleteStudent(studentIndex) }}>Delete Student from Matching</Dropdown.Item>
                                                                    <Dropdown.Item onClick={(e) => { navigator.clipboard.writeText(selectedStudent.studentEmail) }}>Copy Email Address</Dropdown.Item>
                                                                    <Dropdown.Item disabled={this.state.staging.archived} onClick={(e) => { navigator.clipboard.writeText(window.location.href.replace(window.location.pathname, '/survey/skill/' + this.state.staging._id + '/' + generateHash(selectedStudent.studentEmail))) }}>Copy Personal {this.state.staging.stagingType !== "TASKFORCE & BUDDIES" ? "Skill" : "Skill & Preference"} Survey Link</Dropdown.Item>
                                                                    {this.state.staging.stagingType !== "TRENDSEMINAR" && this.state.staging.stagingType !== "TASKFORCE & BUDDIES" && <Dropdown.Item disabled={this.state.staging.archived} onClick={(e) => { navigator.clipboard.writeText(window.location.href.replace(window.location.pathname, '/survey/preference/' + this.state.staging._id + '/' + generateHash(selectedStudent.studentEmail))) }}>Copy Personal Preference Survey Link</Dropdown.Item>}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        )}
                                    </div>
                                    <Col>
                                        <Row>
                                            <Col className="mb-2 mt-1">
                                                <Form>
                                                    <FormControl
                                                        disabled={this.state.staging.archived}
                                                        placeholder={"Add student: Type cdtm email and press +"}
                                                        value={this.state.addStudent}
                                                        onChange={e => {
                                                            this.setState({ addStudent: e.target.value })
                                                        }}
                                                        type="text"
                                                    />
                                                </Form>
                                            </Col>
                                            <Col className="mb-2 mt-1" xs={2}>
                                                <Button disabled={this.state.staging.archived} onClick={e => { this.addStudent() }} variant="secondary" block>+</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Card>
                                <Card className="mb-2">
                                    <h4 className="mt-3 mb-2 ml-3">Projects</h4>
                                    <div className="projectNameList mb-2">
                                        {this.state.staging.projects.map((project, projectIndex) =>
                                            <Col key={project._id}>
                                                <Card className="mb-1 mt-1">
                                                    <Row>
                                                        <Col className="mb-1 mt-1 ml-2">
                                                            {project.projectName}
                                                        </Col>
                                                        {this.state.staging.stagingType !== "TASKFORCE & BUDDIES" && this.state.staging.stagingType !== "TRENDSEMINAR" && <Col onClick={e => { if (!this.state.staging.archived) { this.deleteProject(projectIndex) } }} className="mb-1 mt-1 mr-2 text-right mouseClickable"><X /></Col>}
                                                    </Row>
                                                </Card>
                                            </Col>
                                        )}
                                    </div>
                                    {this.state.staging.stagingType !== "TASKFORCE & BUDDIES" && this.state.staging.stagingType !== "TRENDSEMINAR" && <Col>
                                        <Row>
                                            <Col className="mb-2 mt-1">
                                                <Form>
                                                    <FormControl
                                                        disabled={this.state.staging.archived}
                                                        value={this.state.addProject}
                                                        placeholder={"Add project: Type project name and press +"}
                                                        onChange={e => {
                                                            this.setState({ addProject: e.target.value })
                                                        }}
                                                        type="text"
                                                    />
                                                </Form>
                                            </Col>
                                            <Col className="mb-2 mt-1" xs={2}>
                                                <Button disabled={this.state.staging.archived} onClick={e => { this.addProject() }} variant="secondary" block>+</Button>
                                            </Col>
                                        </Row>
                                    </Col>}
                                </Card>
                            </Col>
                        </Row>
                    </div >
                }
            </div>
        );
    }
}

export default StagingPending;
