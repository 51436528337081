import React, { Component } from "react";
import { Col, Row, Form, Button, ProgressBar, Modal } from "react-bootstrap";
import './creation.css'
import { X } from 'react-bootstrap-icons';
import { withRouter } from 'react-router';

class StagingCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blueprints: null,
            isLoaded: false,
            selection: "blueprint",
            selectedBlueprint: null,
            blueprintIndex: -1,
            activatedQuestions: [],
            stagingName: "",
            studentEmails: [],
            studentEmailString: "",
            projects: [],
            projectString: "",
            projectNumber: 5,
            tmpStudyStatus: "",
            tmpConsultingExp: "",
            tmpWorkingStyle: "",
            tmpWorkingStyleOption: -1,
            tmpLikert: {},
            tmpComment: "",
            showError: false,
            errorMessage: "",
            sending: false,
            taskforceString: "Strategic Partnerships\nPeople Operations\nCommunity Engagement\nMarketing & Branding\nEntrepreneurship\nCenterlife",
            profString: "",
            caString: "",
            taskforces: [],
            professors: [],
            cas: [],
            taskforceSelection: [],
            trendseminarStages: ["Basic", "Scenario", "Ideation"],
            trendseminarTeamsPerStages: [5, 4, 5],
            taskforceQuestions: []
        };
    }

    componentDidMount() {
        //load staging blueprints
        fetch(process.env.REACT_APP_API_URL + "/stagingBlueprints", {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({ blueprints: result, isLoaded: true })
                },
                (error) => {
                    console.log(error)
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }


    submitBlueprint() {
        let today = new Date()
        let semester = today.getMonth() < 6 ? "1" : "2"
        let stagingName = today.getFullYear().toString().substr(-2) + "-" + semester + " " + this.state.selectedBlueprint.name
        let projectString = ""
        if (this.state.selectedBlueprint.name.toUpperCase() === "TRENDSEMINAR") {
            projectString = "Basic\nScenario\nIdeation"
        } else if (this.state.selectedBlueprint.name.toUpperCase() === "TASKFORCE & BUDDIES") {
            this.state.selectedBlueprint.restrictions.map((restriction, index) => {
                if (restriction.restrictionType === "TASKFORCE") {
                    let splitQuestions = restriction.restrictionQuestion.split("\n\n")
                    this.setState({ taskforceQuestions: splitQuestions })
                }
                return null
            })
        }
        this.setState({ selection: "basic", projectString: projectString, stagingName: stagingName })
    }

    changeBlueprint(index) {
        let activatedQuestions = this.state.blueprints[index].restrictions.map(x => { return true })
        this.setState({ selectedBlueprint: this.state.blueprints[index], activatedQuestions, blueprintIndex: index })
    }

    toggleActivatedQuestion(index) {
        let activatedQuestions = this.state.activatedQuestions;
        activatedQuestions[index] = !activatedQuestions[index]
        this.setState({ activatedQuestions })
    }

    checkIncorrectEmails(studentEmails) {
        return studentEmails.reduce((result, studentEmail) => {
            if (!studentEmail.match(/^\w+([\.-]?\w+)*@cdtm.de$/)) {
                result.push(studentEmail)
            }
            return result
        }, [])
    }

    submitBasic() {
        let students = this.state.studentEmailString.toLowerCase().split("\n")
        let incorrectEmails = this.checkIncorrectEmails(students)
        console.log(incorrectEmails)
        if (incorrectEmails.length > 0) {
            this.setState({ showError: true, errorMessage: <div><p>Please make sure all Email Addresses are in the format NAME.NAME@cdtm.de or NAME@cdtm.de</p><p>The following Emails do not confirm to the standard:</p> {incorrectEmails.map(e => <>{e}<br /></>)}</div> })
            return
        }
        let projects = this.state.projectString.split("\n")
        students = students.reduce((result, element) => {
            if (element !== "") {
                element = element.replace(/\s/g, '');
                element = element.toLowerCase();
                result.push(element)
            }
            return result
        }, [])
        if (this.state.selectedBlueprint.name.toUpperCase() === "TRENDSEMINAR") {
            projects = []
            for (let i = 0; i < this.state.trendseminarStages.length; i++) {
                for (let j = 0; j < this.state.trendseminarTeamsPerStages[i]; j++) {
                    projects.push(this.state.trendseminarStages[i] + " - Team " + (j + 1))
                }

            }
        } else if (this.state.selectedBlueprint.name.toUpperCase() === "TASKFORCE & BUDDIES") {
            projects = []
            let taskforces = this.state.taskforceString.split("\n").filter(element => element.replace(/\s/g, '').length)
            let professors = this.state.profString.split("\n").filter(element => element.replace(/\s/g, '').length)
            let cas = this.state.caString.split("\n").filter(element => element.replace(/\s/g, '').length)
            if (taskforces.length > this.state.taskforceQuestions.length + 1) {
                let lastQuestion = this.state.taskforceQuestions[this.state.taskforceQuestions.length - 1]
                let taskforceQuestions = this.state.taskforceQuestions.slice(0, this.state.taskforceQuestions - 1)
                while (taskforces.length < taskforceQuestions.length) {
                    taskforceQuestions.push("")
                }
                taskforceQuestions.push(lastQuestion)
                this.setState({ taskforceQuestions })
            } else if (taskforces.length < this.state.taskforceQuestions.length + 1) {
                let lastQuestion = this.state.taskforceQuestions[this.state.taskforceQuestions.length - 1]
                let taskforceQuestions = this.state.taskforceQuestions.slice(0, taskforces.length)
                taskforceQuestions.push(lastQuestion)
                this.setState({ taskforceQuestions })
            }
            this.state.selectedBlueprint.restrictions.map((restriction, index) => {
                let blueprint = this.state.selectedBlueprint
                if (restriction.restrictionType === "LIKERTPREFERENCE" || restriction.restrictionType === "TASKFORCE") {
                    if (restriction.restriction.abbreviation === "CA") {
                        blueprint.restrictions[index].restriction.options = cas
                    } else if (restriction.restriction.abbreviation === "PROF") {
                        blueprint.restrictions[index].restriction.options = professors

                    } else if (restriction.restriction.abbreviation === "TF") {
                        blueprint.restrictions[index].restriction.options = taskforces
                        let tfCas = []
                        taskforces.forEach(element => {
                            tfCas.push(cas[0])
                        })
                        blueprint.restrictions[index].restriction.cas = tfCas
                    }
                }
                this.setState({ selectedBlueprint: blueprint })
                return null
            })
            for (let i = 0; i < taskforces.length; i++) {
                if (taskforces[i].replace(/\s/g, '').length)
                    projects.push("TF - " + taskforces[i])
            }
            for (let i = 0; i < cas.length; i++) {
                if (cas[i].replace(/\s/g, '').length) {
                    projects.push("CA - " + cas[i])
                }
            }
            for (let i = 0; i < professors.length; i++) {
                if (professors[i].replace(/\s/g, '').length) {
                    projects.push("PROF - " + professors[i])
                }
            }
            this.setState({ taskforces, professors, cas, taskforceSelection: new Array(taskforces.length) })
        }
        projects = projects.reduce((result, element) => {
            if (element !== "") {
                result.push(element)
            }
            return result
        }, [])
        let next = this.state.selectedBlueprint.name.toUpperCase() === "TASKFORCE & BUDDIES" ? "taskforce" : "restrictions"
        this.setState({ studentEmails: students, projects, selection: next })
    }

    setRestrictionQuestion(question, restrictionIndex) {
        let blueprint = this.state.selectedBlueprint
        blueprint.restrictions[restrictionIndex].restrictionQuestion = question
        this.setState({ selectedBlueprint: blueprint })
    }

    renderBackground() {
        let set = false
        let renderedBackground = this.state.selectedBlueprint.restrictions.map((restriction, index) => {
            if (restriction.restrictionType === "BACKGROUND") {
                set = true
                return (
                    <div key={index} className={`${this.state.activatedQuestions[index] ? "" : "deactivated"}`}>
                        <Row className="mb-2 mt-2"><Col><h3>{restriction.restrictionName}</h3></Col><Col className="mt-auto mb-auto" sm={1}><input type="checkbox" checked={this.state.activatedQuestions[index]} onChange={event => this.toggleActivatedQuestion(index)}></input></Col></Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="projects">
                                    <Form.Label><b>Question:</b></Form.Label>
                                    <Form.Control disabled={this.state.sending || !this.state.activatedQuestions[index]} value={restriction.restrictionQuestion} required type="text" placeholder={"Type a question the student is asked for " + restriction.restrictionType} onChange={event => this.setRestrictionQuestion(event.target.value, index)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        {this.state.activatedQuestions[index] &&
                            <>
                                <Row>
                                    <Col>
                                        <b>{restriction.restrictionName} Options:</b>
                                    </Col>
                                </Row>
                                {
                                    restriction.restriction.options.map(option => {
                                        switch (option) {
                                            case 'O':
                                                return <Row key={option}><Col>Other</Col></Row>
                                            case 'CS':
                                                return <Row key={option}><Col>Computer Science</Col></Row>
                                            case 'BA':
                                                return <Row key={option}><Col>Business Administration</Col></Row>
                                            case 'EE':
                                                return <Row key={option}><Col>Electrical Engineering</Col></Row>
                                            default:
                                                return <Row key={option}><Col>{option}</Col></Row>
                                        }
                                    })
                                }</>
                        }
                    </div>
                )
            }
            return null
        })
        if (set)
            renderedBackground.push(<hr />)
        return renderedBackground
    }

    changeStudyStatusOptions(restrictionIndex, optionIndex, changedOption) {
        let blueprint = this.state.selectedBlueprint;
        blueprint.restrictions[restrictionIndex].restriction.options[optionIndex] = changedOption
        this.setState({ selectedBlueprint: blueprint })
    }

    removeStudyStatusOptions(restrictionIndex, optionIndex) {
        let blueprint = this.state.selectedBlueprint;
        blueprint.restrictions[restrictionIndex].restriction.options.splice(optionIndex, 1)
        this.setState({ selectedBlueprint: blueprint })
    }

    addStudyStatusOptions(restrictionIndex, addedOption) {
        let blueprint = this.state.selectedBlueprint;
        blueprint.restrictions[restrictionIndex].restriction.options.push(addedOption)
        this.setState({ selectedBlueprint: blueprint, tmpStudyStatus: "" })
    }

    addConsultingExpOptions(restrictionIndex, addedOption) {
        let blueprint = this.state.selectedBlueprint;
        blueprint.restrictions[restrictionIndex].restriction.options.push(addedOption)
        this.setState({ selectedBlueprint: blueprint, tmpConsultingExp: "" })
    }

    renderTaskforce() {
        let render = this.state.selectedBlueprint.restrictions.map((restriction, index) => {
            let blueprint = this.state.selectedBlueprint;
            if (restriction.restrictionType === "TASKFORCE") {
                let html = []
                html.push(<Row className="mb-2 mt-2"><Col><h3>{restriction.restrictionName}</h3></Col><Col className="mt-auto mb-auto" sm={1}><input type="checkbox" checked={this.state.activatedQuestions[index]} onChange={event => this.toggleActivatedQuestion(index)}></input></Col></Row>)
                for (let i = 0; i < this.state.taskforces.length; i++) {
                    html.push(<>
                        <Row>
                            <Col>
                                <Form.Group controlId="projects" >
                                    <Form.Label><b>Explanation {this.state.taskforces[i]}:</b></Form.Label>
                                    <Form.Control disabled={this.state.sending || !this.state.activatedQuestions[index]} value={this.state.taskforceQuestions[i]} required as="textarea" rows={10} placeholder={"Type a question the student is asked for " + restriction.restrictionType} onChange={event => { this.setState({ taskforceQuestions: this.state.taskforceQuestions.map((e, ix) => ix === i ? event.target.value : e) }) }} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Col>
                                    <b>{this.state.taskforces[i]} CA:</b>
                                </Col>
                            </Col>
                            <Col>
                                <Form.Group controlId="exampleForm.SelectCustom">
                                    <Form.Control disabled={this.state.sending || !this.state.activatedQuestions[index]} value={this.state.taskforceSelection[i]} as="select" custom onChange={event => {
                                        blueprint.restrictions[index].restriction.cas[i] = event.target.value; this.setState({ selectedBlueprint: blueprint })
                                    }}>
                                        <option disabled value={-1}> -- select an option -- </option>
                                        {this.state.cas.map((value, valueIndex) => {
                                            return <option key={valueIndex} value={value}>{value}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                    </>)
                }
                html.push(<Row>
                    <Col>
                        <Form.Group controlId="projects" >
                            <Form.Label><b>General Comment:</b></Form.Label>
                            <Form.Control disabled={this.state.sending || !this.state.activatedQuestions[index]} value={this.state.taskforceQuestions[this.state.taskforceQuestions.length - 1]} required as="textarea" rows={10} placeholder={"Type a question the student is asked for " + restriction.restrictionType} onChange={event => { this.setState({ taskforceQuestions: this.state.taskforceQuestions.map((e, ix) => ix === this.state.taskforceQuestions.length - 1 ? event.target.value : e) }) }} />
                        </Form.Group>
                    </Col>
                </Row>)
                return html
            }
            return null
        })
        return render
    }

    renderConstantOptions() {
        let set = false
        let render = this.state.selectedBlueprint.restrictions.map((restriction, index) => {
            if (restriction.restrictionType === "STUDYSTATUS" || restriction.restrictionType === "OPTIONS" || restriction.restrictionType === "CONSULTINGEXP") {
                set = true
                return (
                    <div key={index} className={`${this.state.activatedQuestions[index] ? "" : "deactivated"}`}>
                        <Row className="mb-2 mt-2"><Col><h3>{restriction.restrictionName}</h3></Col><Col className="mt-auto mb-auto" sm={1}><input type="checkbox" checked={this.state.activatedQuestions[index]} onChange={event => this.toggleActivatedQuestion(index)}></input></Col></Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="projects">
                                    <Form.Label><b>Question:</b></Form.Label>
                                    <Form.Control disabled={this.state.sending || !this.state.activatedQuestions[index]} value={restriction.restrictionQuestion} required type="text" placeholder={"Type a question the student is asked for " + restriction.restrictionType} onChange={event => this.setRestrictionQuestion(event.target.value, index)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        {this.state.activatedQuestions[index] &&
                            <>
                                <Row>
                                    <Col>
                                        <b>{restriction.restrictionName} Options:</b>
                                    </Col>
                                </Row>
                                {
                                    restriction.restriction.options.map((option, optionIndex) => {
                                        return <Row key={optionIndex}>
                                            <Col>
                                                <Form.Group controlId="studyStatusOption">
                                                    <Form.Control disabled={this.state.sending || !this.state.activatedQuestions[index]} value={option} required type="text" placeholder={"Type an option for " + restriction.restrictionName} onChange={event => this.changeStudyStatusOptions(index, optionIndex, event.target.value)} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={1}>
                                                <Button disabled={this.state.sending || restriction.restriction.options.length <= 2} variant="light" onClick={event => { this.removeStudyStatusOptions(index, optionIndex) }}><X /></Button>
                                            </Col>
                                        </Row>
                                    })

                                }
                                <Row>
                                    <Col>
                                        <Form.Group controlId="studyStatusOption">
                                            <Form.Control disabled={this.state.sending || !this.state.activatedQuestions[index]} value={this.state.tmpStudyStatus} type="text" placeholder={"Add new option for " + restriction.restrictionName} onChange={event => this.setState({ tmpStudyStatus: event.target.value })} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={1}>
                                        <Button disabled={this.state.sending || this.state.tmpStudyStatus === ""} variant="secondary" onClick={event => { this.addStudyStatusOptions(index, this.state.tmpStudyStatus) }}><b>+</b></Button>
                                    </Col>
                                </Row>
                            </>
                        }
                    </div >
                )
            }
            return null
        })
        if (set)
            render.push(<hr />)
        return render
    }

    renderLikertPreference() {
        let set = false
        let render = this.state.selectedBlueprint.restrictions.map((restriction, index) => {
            if (restriction.restrictionType === "LIKERTPREFERENCE") {
                set = true
                return (
                    <div key={index} className={`${this.state.activatedQuestions[index] ? "" : "deactivated"}`}>
                        <Row className="mb-2 mt-2"><Col><h3>{restriction.restrictionName}</h3></Col><Col className="mt-auto mb-auto" sm={1}><input type="checkbox" checked={this.state.activatedQuestions[index]} onChange={event => this.toggleActivatedQuestion(index)}></input></Col></Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="projects">
                                    <Form.Label><b>Question:</b></Form.Label>
                                    <Form.Control disabled={this.state.sending || !this.state.activatedQuestions[index]} value={restriction.restrictionQuestion} required as="textarea" rows={10} placeholder={"Type a question the student is asked for " + restriction.restrictionType} onChange={event => this.setRestrictionQuestion(event.target.value, index)} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div >
                )
            }
            return null
        })
        if (set)
            render.push(<hr />)
        return render
    }



    changeWorkingStyleAffiliation(restrictionIndex, optionIndex, value) {
        let blueprint = this.state.selectedBlueprint;
        blueprint.restrictions[restrictionIndex].restriction.options[optionIndex] = value;
        this.setState({ selectedBlueprint: blueprint })
    }

    removeWorkingStyleOptions(restrictionIndex, optionIndex) {
        let blueprint = this.state.selectedBlueprint;
        delete blueprint.restrictions[restrictionIndex].restriction.options[optionIndex]
        this.setState({ selectedBlueprint: blueprint })
    }

    addWorkingStyleOptions(restrictionIndex, addedOption, option) {
        let blueprint = this.state.selectedBlueprint;
        blueprint.restrictions[restrictionIndex].restriction.options[addedOption] = option
        this.setState({ selectedBlueprint: blueprint, tmpStudyStatus: "" })
    }

    getWorkingStyleOptions(options) {
        return [...new Set(options)]
    }

    renderWorkingStyle() {
        let set = false
        let render = this.state.selectedBlueprint.restrictions.map((restriction, index) => {
            if (restriction.restrictionType === "WORKINGSTYLE") {
                set = true
                return (
                    <div key={index} className={`${this.state.activatedQuestions[index] ? "" : "deactivated"}`}>
                        <Row className="mb-2 mt-2"><Col><h3>{restriction.restrictionName}</h3></Col><Col className="mt-auto mb-auto" sm={1}><input type="checkbox" checked={this.state.activatedQuestions[index]} onChange={event => this.toggleActivatedQuestion(index)}></input></Col></Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="projects">
                                    <Form.Label><b>Question:</b></Form.Label>
                                    <Form.Control disabled={this.state.sending || !this.state.activatedQuestions[index]} value={restriction.restrictionQuestion} required type="text" placeholder={"Type a question the student is asked for " + restriction.restrictionType} onChange={event => this.setRestrictionQuestion(event.target.value, index)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        {this.state.activatedQuestions[index] &&
                            <>
                                <Row>
                                    <Col>
                                        <b>{restriction.restrictionName} Options:</b>
                                    </Col>
                                </Row>
                                {
                                    Object.keys(restriction.restriction.options).map((key, keyIndex) => {
                                        return (
                                            <Row key={keyIndex}>
                                                <Col className="mt-auto mb-auto">
                                                    {key}
                                                </Col>
                                                <Col>
                                                    <Form.Group controlId="exampleForm.SelectCustom">
                                                        <Form.Control disabled={this.state.sending || !this.state.activatedQuestions[index]} value={restriction.restriction.options[key]} as="select" custom onChange={event => this.changeWorkingStyleAffiliation(index, key, event.target.value)}>
                                                            {this.getWorkingStyleOptions(Object.values(restriction.restriction.options)).map((value) => {
                                                                return <option key={value} value={value}>{value.charAt(0).toUpperCase()}{value.slice(1).toLowerCase()}</option>
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={1}>
                                                    <Button disabled={this.state.sending || Object.keys(restriction.restriction.options).length <= 8} variant="light" onClick={event => { this.removeWorkingStyleOptions(index, key) }}><X /></Button>
                                                </Col>
                                            </Row>)
                                    })
                                }
                                <Row>
                                    <Col>
                                        <Form.Group controlId="studyStatusOption">
                                            <Form.Control disabled={this.state.sending || !this.state.activatedQuestions[index]} value={this.state.tmpWorkingStyle} type="text" placeholder={"Add new option for " + restriction.restrictionName} onChange={event => this.setState({ tmpWorkingStyle: event.target.value })} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="exampleForm.SelectCustom">
                                            <Form.Control disabled={this.state.sending || !this.state.activatedQuestions[index]} value={this.state.tmpWorkingStyleOption} as="select" custom onChange={event => this.setState({ tmpWorkingStyleOption: event.target.value })}>
                                                <option disabled value={-1}> -- select an option -- </option>
                                                {this.getWorkingStyleOptions(Object.values(restriction.restriction.options)).map((value, valueIndex) => {
                                                    return <option key={valueIndex} value={value}>{value.charAt(0).toUpperCase()}{value.slice(1).toLowerCase()}</option>
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={1}>
                                        <Button disabled={this.state.sending || this.state.tmpWorkingStyle === "" || !this.state.activatedQuestions[index] || this.state.tmpWorkingStyleOption === -1} variant="secondary" onClick={event => { this.addWorkingStyleOptions(index, this.state.tmpWorkingStyle, this.state.tmpWorkingStyleOption) }}><b>+</b></Button>
                                    </Col>
                                </Row>
                            </>
                        }
                    </div>
                )
            }
            return null
        })
        if (set)
            render.push(<hr />)
        return render
    }


    setRestrictionName(name, restrictionIndex) {
        let blueprint = this.state.selectedBlueprint
        blueprint.restrictions[restrictionIndex].restrictionName = name
        this.setState({ selectedBlueprint: blueprint })
    }

    removeRestriction(restrictionIndex) {
        let blueprint = this.state.selectedBlueprint;
        blueprint.restrictions.splice(restrictionIndex, 1)
        let activatedQuestions = this.state.activatedQuestions
        activatedQuestions.splice(restrictionIndex, 1)
        this.setState({ selectedBlueprint: blueprint, activatedQuestions })
    }

    addLikert(likertValue, likertCategory) {
        let blueprint = this.state.selectedBlueprint;
        blueprint.restrictions.push({
            restriction: { category: [likertCategory] },
            restrictionName: likertValue[likertCategory],
            restrictionQuestion: likertValue[likertCategory],
            restrictionType: "LIKERT"
        })
        likertValue[likertCategory] = ""
        let activatedQuestions = this.state.activatedQuestions
        activatedQuestions.push(true)
        this.setState({ selectedBlueprint: blueprint, tmpLikert: likertValue, activatedQuestions })
    }


    renderLikert() {
        let set = false
        let restrictions = this.state.selectedBlueprint.restrictions.reduce((result, restriction, index) => {
            if (restriction.restrictionType === "LIKERT") {
                set = true
                let tmp = result.get(restriction.restriction.category[0])
                tmp = tmp === undefined ? new Array({ restriction: restriction, index: index }) : tmp.concat({ restriction: restriction, index: index })
                result.set(restriction.restriction.category[0], tmp)
            }
            return result
        }, new Map())
        let html = []
        if (restrictions && restrictions.length > 0) {
            html.push(<Row className="mb-2 mt-2"><Col><h3>Likert (5 Star Rating)</h3></Col></Row>)
        }
        restrictions.forEach((val, key) => {
            html.push(<Row className="mb-2 mt-2"><Col><h5>{key}</h5></Col><Col className="mt-auto mb-auto" sm={1}></Col></Row>)
            if (restrictions.get(key)[0].restriction.restriction.description) {
                html.push(<Row className="mb-2 mt-2"><Col className="allow-newline">{restrictions.get(key)[0].restriction.restriction.description}</Col><Col className="mt-auto mb-auto" sm={1}></Col></Row>)
            }
            html.push(restrictions.get(key).map((likert, likerId) => {
                return (
                    <Row key={likerId}>
                        <Col>
                            <Form.Group controlId="projects">
                                <Form.Control disabled={this.state.sending} value={likert.restriction.restrictionQuestion} required type="text" placeholder={"Type a question the student is asked for " + likert.restriction.restrictionType} onChange={event => { this.setRestrictionQuestion(event.target.value, likert.index); this.setRestrictionName(event.target.value, likert.index) }} />
                            </Form.Group>
                        </Col>
                        <Col xs={1}>
                            <Button disabled={this.state.sending} variant="light" onClick={event => { this.removeRestriction(likert.index) }}><X /></Button>
                        </Col>
                    </Row>)
            }))
            html.push(
                <Row>
                    <Col>
                        <Form.Group controlId="studyStatusOption">
                            <Form.Control disabled={this.state.sending} value={this.state.tmpLikert[key]} type="text" placeholder={"Add new option for " + key} onChange={event => { let tmpLikert = this.state.tmpLikert; tmpLikert[key] = event.target.value; this.setState({ tmpLikert }) }} />
                        </Form.Group>
                    </Col>
                    <Col xs={1}>
                        <Button disabled={this.state.sending || this.state.tmpLikert[key] === undefined || this.state.tmpLikert[key] === ""} variant="secondary" onClick={event => { this.addLikert(this.state.tmpLikert, key) }}><b>+</b></Button>
                    </Col>
                </Row>
            )
        })
        if (set)
            html.push(<hr />)
        return html
    }

    addNewComment(question) {
        let blueprint = this.state.selectedBlueprint;
        blueprint.restrictions.push({
            restriction: {},
            restrictionName: question,
            restrictionQuestion: question,
            restrictionType: "COMMENT"
        })
        let activatedQuestions = this.state.activatedQuestions
        activatedQuestions.push(true)
        this.setState({ selectedBlueprint: blueprint, tmpComment: "", activatedQuestions })
    }

    renderComment() {
        let html = this.state.selectedBlueprint.restrictions.map((restriction, index) => {
            if (restriction.restrictionType === "COMMENT") {
                return (
                    <div>
                        <Row>
                            <Col>
                                <Form.Group controlId="projects">
                                    <Form.Control disabled={this.state.sending} value={restriction.restrictionQuestion} required type="text" placeholder={"Type a question the student is asked for " + restriction.restrictionType} onChange={event => { this.setRestrictionQuestion(event.target.value, index); this.setRestrictionName(event.target.value, index) }} />
                                </Form.Group>
                            </Col>
                            <Col xs={1}>
                                <Button disabled={this.state.sending} variant="light" onClick={event => { this.removeRestriction(index) }}><X /></Button>
                            </Col>
                        </Row>
                    </div >
                )
            }
            return null
        })
        html.push(
            <Row>
                <Col>
                    <Form.Group controlId="studyStatusOption">
                        <Form.Control disabled={this.state.sending} value={this.state.tmpComment} type="text" placeholder={"Add new option for Comment"} onChange={event => { this.setState({ tmpComment: event.target.value }) }} />
                    </Form.Group>
                </Col>
                <Col xs={1}>
                    <Button disabled={this.state.sending || this.state.tmpComment === ""} variant="secondary" onClick={event => { this.addNewComment(this.state.tmpComment) }}><b>+</b></Button>
                </Col>
            </Row>
        )
        return html
    }

    submitTaskforce() {
        let questionString = this.state.taskforceQuestions
        this.state.selectedBlueprint.restrictions.map((restriction, index) => {
            let blueprint = this.state.selectedBlueprint;
            if (restriction.restrictionType === "TASKFORCE") {
                for (let i = 0; i < this.state.taskforces.length; i++) {
                    questionString[i] = questionString[i].replace("%CA%", blueprint.restrictions[index].restriction.cas[i])
                }
                questionString = questionString.join("\n\n")
                this.setRestrictionQuestion(questionString, index)
            }
            return null
        })
        this.setState({ selection: "restrictions" })
    }

    submitRestrictions() {
        let staging = {}
        staging.stagingType = this.state.selectedBlueprint.name.toUpperCase()
        staging.projects = this.state.projects
        staging.students = this.state.studentEmails
        staging.stagingName = this.state.stagingName
        staging.selectedStudents = this.state.studentEmails.map(email => {
            return {
                studentEmail: email,
                skillSurveyStatus: "NONE",
                preferenceSurveyStatus: "NONE"
            }
        })
        let restrictions = this.state.selectedBlueprint.restrictions.reduce((result, element, index) => {
            if (this.state.activatedQuestions[index]) {
                result.push(element)
            }
            return result
        }, [])
        staging.restrictions = restrictions



        fetch(process.env.REACT_APP_API_URL + '/stagingFromBlueprint', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
            },
            body: JSON.stringify(staging)
        }).then((result) => {
            this.setState({ sending: false })
            return result.json();
        }).then((result) => {
            if (result.error) {
                this.setState({ showError: true, errorMessage: result.error })
            } else {
                this.props.history.push('/pending/' + result._id);
            }

        },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    }

    renderCreateStagesTrendseminar() {
        let html = []
        for (let i = 0; i < this.state.trendseminarStages.length; i++) {
            html.push(<Form>
                <Row>
                    <Col>
                        <b>Stage {i + 1}:</b>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group >
                            <Form.Label>Name of stage {i + 1}:</Form.Label>
                            <Form.Control value={this.state.trendseminarStages[i]} required type="text" placeholder={"Trendseminar stage nr " + i} onChange={(event) => { let trendseminarStages = this.state.trendseminarStages; trendseminarStages[i] = event.target.value; this.setState({ trendseminarStages }) }} />
                        </Form.Group>

                    </Col>
                    <Col xs={1}>
                        <Button disabled={this.state.sending} variant="light" onClick={event => { let trendseminarTeamsPerStages = this.state.trendseminarTeamsPerStages; trendseminarTeamsPerStages.splice(i, 1); let trendseminarStages = this.state.trendseminarStages; trendseminarStages.splice(i, 1); this.setState({ trendseminarStages, trendseminarTeamsPerStages }) }}><X /></Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={11}>
                        <Form.Group >
                            <Form.Label>Number of teams in stage {i + 1}:</Form.Label>
                            <Form.Control value={this.state.trendseminarTeamsPerStages[i]} required type="number" placeholder={"Nr Teams in stage " + i} onChange={(event) => { let trendseminarTeamsPerStages = this.state.trendseminarTeamsPerStages; trendseminarTeamsPerStages[i] = event.target.value ? parseInt(event.target.value) : ""; this.setState({ trendseminarTeamsPerStages }) }} />
                        </Form.Group>
                    </Col>
                </Row></Form>)
        }
        html.push(<Row>
            <Col >
                <Button disabled={this.state.sending} variant="outline-primary" onClick={event => { this.setState({ trendseminarTeamsPerStages: [...this.state.trendseminarTeamsPerStages, 0], trendseminarStages: [...this.state.trendseminarStages, "New Stage"] }) }}>Add new Stage</Button>
            </Col>
        </Row>)
        return html
    }

    render() {
        return (
            <div className="pb-2">
                {this.state.isLoaded && this.state.selection === "blueprint" &&
                    <Col xs={12} sm={10} md={8} xl={6} className="ml-auto mr-auto mt-5 mb-5 card">
                        <Row className="mt-2"><Col><h2>Create new Matching</h2></Col></Row>
                        <Row className="mb-4"><Col><ProgressBar now={33} label={"1/3"} /></Col></Row>
                        <Form onSubmit={(event) => { event.preventDefault(); this.submitBlueprint() }}>
                            <Row className="mb-2 mt-2"><Col>

                                <Form.Group controlId="exampleForm.SelectCustom">
                                    <Form.Label><b>Select a Course:</b></Form.Label>
                                    <Form.Control value={this.state.blueprintIndex} as="select" custom onChange={event => this.changeBlueprint(event.target.value)}>
                                        <option disabled value={-1}> -- select an option -- </option>
                                        {this.state.blueprints.map((blueprint, index) => {
                                            return <option key={index} value={index}>{blueprint.name}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>

                            </Col></Row>
                            <Button variant="primary" type="submit" className="mb-4 mt-2 float-right" disabled={this.state.sending || this.state.selectedBlueprint === null}>
                                Select
                            </Button>
                        </Form>
                    </Col>
                }
                {this.state.isLoaded && this.state.selection === "basic" &&
                    <Col xs={12} sm={10} md={8} xl={6} className="ml-auto mr-auto mt-5 mb-5 card">
                        <Row className="mt-2"><Col><h2>Give basic information</h2></Col></Row>
                        {this.state.selectedBlueprint.name.toUpperCase() === "TASKFORCE & BUDDIES" ? <Row className="mb-4"><Col><ProgressBar now={50} label={"2/4"} /></Col></Row> : <Row className="mb-4"><Col><ProgressBar now={66} label={"2/3"} /></Col></Row>}
                        <Form onSubmit={(event) => { event.preventDefault(); this.submitBasic() }}>
                            <Row className="mb-2 mt-2"><Col>

                                <Row>
                                    <Col>
                                        <Form.Group controlId="stagingName">
                                            <Form.Label><b>Name of Matching:</b></Form.Label>
                                            <Form.Control value={this.state.stagingName} required type="text" placeholder="Name of Matching" onChange={(event) => { this.setState({ stagingName: event.target.value }) }} />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group controlId="students">
                                            <Form.Label><b>Student Emails (separated by new line):</b></Form.Label>
                                            <Form.Control value={this.state.studentEmailString} required as="textarea" rows={20} placeholder="Type student emails and press 'enter' to separate or copy from excel column" onChange={(event) => { this.setState({ studentEmailString: event.target.value }) }} />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {this.state.selectedBlueprint.name.toUpperCase() === "TRENDSEMINAR" ?
                                    <div>
                                        {this.renderCreateStagesTrendseminar()}
                                    </div>
                                    : this.state.selectedBlueprint.name.toUpperCase() === "TASKFORCE & BUDDIES" ?
                                        <div>
                                            <Row>
                                                <Col>
                                                    <Form.Group controlId="projects">
                                                        <Form.Label><b>Names of taskforces (separated by new line):</b></Form.Label>
                                                        <Form.Control value={this.state.taskforceString} required as="textarea" rows={7} placeholder="Type taskforce names" onChange={(event) => { this.setState({ taskforceString: event.target.value }) }} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group controlId="projects">
                                                        <Form.Label><b>Names of CAs (separated by new line):</b></Form.Label>
                                                        <Form.Control value={this.state.caString} required as="textarea" rows={5} placeholder="Type CA names" onChange={(event) => { this.setState({ caString: event.target.value }) }} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group controlId="projects">
                                                        <Form.Label><b>Names of Professor Advisors (separated by new line):</b></Form.Label>
                                                        <Form.Control value={this.state.profString} required as="textarea" rows={10} placeholder="Type Professor names" onChange={(event) => { this.setState({ profString: event.target.value }) }} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                        :
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="projects">
                                                    <Form.Label><b>Projects (separated by new line):</b></Form.Label>
                                                    <Form.Control value={this.state.projectString} required as="textarea" rows={5} placeholder="Type project names" onChange={(event) => { this.setState({ projectString: event.target.value }) }} />
                                                </Form.Group>
                                            </Col>
                                        </Row>}

                            </Col></Row>
                            <Button disabled={this.state.sending} variant="secondary" className="mb-4 mt-2" onClick={(event) => { event.preventDefault(); this.setState({ selection: "blueprint" }) }}>
                                Back
                            </Button>
                            {/* eslint-disable-next-line*/}
                            <Button variant="primary" type="submit" className="mb-4 mt-2 float-right" disabled={this.state.sending || this.state.stagingName === "" || this.state.studentEmailString === "" || ((this.state.selectedBlueprint.name.toUpperCase() !== "TASKFORCE & BUDDIES" && this.state.selectedBlueprint.name.toUpperCase() !== "TRENDSEMINAR") && (this.state.projectString === "" || this.state.projectNumber === 0)) || (this.state.selectedBlueprint.name.toUpperCase() === "TASKFORCE & BUDDIES" && (this.state.profString === "" || this.state.taskforceString === "" || this.state.caString === "")) || (this.state.selectedBlueprint.name.toUpperCase() === "TRENDSEMINAR" && (this.state.trendseminarStages.length === 0 || this.state.trendseminarStages.indexOf("") !== -1) || this.state.trendseminarTeamsPerStages.indexOf("") !== -1)}>
                                Next
                            </Button>
                        </Form >
                    </Col >
                }
                {this.state.isLoaded && this.state.selection === "taskforce" &&
                    <Col xs={12} sm={10} md={8} xl={6} className="ml-auto mr-auto mt-5 mb-5 card">
                        <Row className="mt-2"><Col><h2>Choose questions asked to students</h2></Col></Row>
                        <Row className="mb-4"><Col><ProgressBar now={75} label={"3/4"} /></Col></Row>
                        <Form onSubmit={(event) => { event.preventDefault(); this.submitTaskforce() }}>
                            <Row className="mb-2 mt-2"><Col>
                                {this.renderTaskforce()}
                            </Col></Row>
                            <Button disabled={this.state.sending} variant="secondary" className="mb-4 mt-2" onClick={(event) => { event.preventDefault(); this.setState({ selection: "basic" }) }}>
                                Back
                            </Button>
                            <Button disabled={this.state.sending} variant="primary" type="submit" className="mb-4 mt-2 float-right" >
                                Next
                            </Button>
                        </Form>
                    </Col>
                }
                {
                    this.state.isLoaded && this.state.selection === "restrictions" &&
                    <Col xs={12} sm={10} md={8} xl={6} className="ml-auto mr-auto mt-5 mb-5 card">
                        <Row className="mt-2"><Col><h2>Choose questions asked to students</h2></Col></Row>
                        {this.state.selectedBlueprint.name.toUpperCase() === "TASKFORCE & BUDDIES" ? <Row className="mb-4"><Col><ProgressBar now={100} label={"4/4"} /></Col></Row> : <Row className="mb-4"><Col><ProgressBar now={100} label={"3/3"} /></Col></Row>}
                        <Form onSubmit={(event) => { event.preventDefault(); this.setState({ sending: true }); this.submitRestrictions() }}>
                            <Row className="mb-2 mt-2"><Col>
                                {this.renderBackground()}
                                {this.renderLikertPreference()}
                                {this.renderConstantOptions()}
                                {this.renderWorkingStyle()}
                                {this.renderLikert()}
                                <Row className="mb-2 mt-2"><Col><h3>Comment fields</h3></Col></Row>
                                {this.renderComment()}
                            </Col></Row>
                            <Button disabled={this.state.sending} variant="secondary" className="mb-4 mt-2" onClick={(event) => { event.preventDefault(); let back = this.state.selectedBlueprint.name.toUpperCase() === "TASKFORCE & BUDDIES" ? "taskforce" : "basic"; this.setState({ selection: back }) }}>
                                Back
                            </Button>
                            <Button disabled={this.state.sending} variant="primary" type="submit" className="mb-4 mt-2 float-right" >
                                Create
                            </Button>
                        </Form>
                    </Col>
                }
                <Modal show={this.state.showError} onHide={(e) => { this.setState({ showError: false }) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Creation Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={(e) => { this.setState({ showError: false }) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div >
        );
    }
}

export default withRouter(StagingCreation);
