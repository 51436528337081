import React, { Component } from "react";
import "./App.css";
import './theme.scss';
import Header from "./Header/header";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import MPDstaging from "./MPD/mpd.staging";
import MPDmatching from "./MPD/mpd.matching";
import MPDstagingSelection from "./MPD/mpd.stagingSelection";
import StudentProfile from "./Student/student.profile";
import { StudentSkillSurvey } from "./Survey/studentsurvey.skills";
import { StudentSurveyFinished } from "./Survey/studentsurveyfinished";
import { StudentPreferenceSurvey } from "./Survey/studentsurvey.preference";
import StagingCreation from "./Creation/staging.creation";
import StagingPending from "./Pending/staging.pending";
import { keycloak } from './keycloak'
import { KeycloakProvider } from '@react-keycloak/web'
import { PrivateRoute } from './utilities/PrivateRoute';
import Unauthorized from "./utilities/unauthorized";
import { Loading } from "./utilities/loading";


class App extends Component {
  constructor() {
    super();
    this.state = {
      authenticated: false,
      initialized: false
    };
  }

  componentDidMount() {
    document.title = "CDTM Matching"
  }


  render() {
    return (
      <KeycloakProvider keycloak={keycloak} LoadingComponent={Loading}  >
        <div div className="App">
          <Router>
            <Header />
            <div className="appBody">
              <Switch >
                <Route exact path="/" >
                  <Redirect to="/home" />
                </Route>
                <PrivateRoute roles={process.env.REACT_APP_KEYCLOAK_ROLES.split(' ')} exact path="/home" component={MPDstagingSelection} />
                <PrivateRoute roles={process.env.REACT_APP_KEYCLOAK_ROLES.split(' ')} exact path="/matching/:id" component={MPDmatching} />
                <PrivateRoute roles={process.env.REACT_APP_KEYCLOAK_ROLES.split(' ')} exact path="/staging/:id" component={MPDstaging} />
                <PrivateRoute roles={process.env.REACT_APP_KEYCLOAK_ROLES.split(' ')} exact path="/stagingSelection" component={MPDstagingSelection} />
                <PrivateRoute roles={process.env.REACT_APP_KEYCLOAK_ROLES.split(' ')} exact path="/student/:id" component={StudentProfile} />
                <Route exact path="/survey/skill/:id/:studentHash" component={StudentSkillSurvey} />
                <Route exact path="/survey/preference/:id/:studentHash" component={StudentPreferenceSurvey} />
                <Route exact path="/surveyfinished" component={StudentSurveyFinished} />
                <Route exact path="/unauthorized" component={Unauthorized} />
                <PrivateRoute roles={process.env.REACT_APP_KEYCLOAK_ROLES.split(' ')} exact path="/create" component={StagingCreation} />
                <PrivateRoute roles={process.env.REACT_APP_KEYCLOAK_ROLES.split(' ')} exact path="/pending/:id" component={StagingPending} />
              </Switch>
            </div>
          </Router>
        </div>
      </KeycloakProvider >
    );
  }
}

export default App;
