function separateStages(projects) {
    let stages = []
    let teams = []
    for (let i = 0; i < projects.length; i++) {
        let phase = projects[i].projectName.substr(0, projects[i].projectName.indexOf(' -'));
        if (stages.indexOf(phase) === -1) {
            teams.push(0)
            stages.push(phase)
        }
        teams[teams.length - 1] += 1
    }
    let separated = {}
    separated["stages"] = stages
    separated["teams"] = teams
    return separated
}

function checkStageAndIndex(projectIndex, separatedStages) {
    let sum = 0;
    for (let i = 0; i < separatedStages["teams"].length; i++) {
        if (projectIndex < sum + separatedStages["teams"][i]) {
            return { stage: separatedStages["stages"][i], teamIndexInStage: projectIndex - sum, stageIndex: i }
        }
        sum += separatedStages["teams"][i]
    }
}

function getMaxRestriction(students, stagingId) {
    let maxRestrictions = {}
    for (let studentCounter = 0; studentCounter < students.length; studentCounter++) {
        let student = students[studentCounter]
        try {
            if (!student.stagings || student.stagings.get(stagingId) == null) {
                break
            }
        } catch (e) {
            break
        }
        student.stagings.get(stagingId).forEach((attribute, key) => {
            if (!maxRestrictions[key]) {
                maxRestrictions[key] = {}
            }
            if (!maxRestrictions[key][attribute.value]) {
                maxRestrictions[key][attribute.value] = 0
            }
            maxRestrictions[key][attribute.value]++
            return
        })
    }
    return maxRestrictions
}


export { separateStages, checkStageAndIndex, getMaxRestriction }