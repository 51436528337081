import React, { Component } from "react";
import "bootstrap/js/src/collapse.js";
import { Card, Col, Row, Button, Accordion, Image, OverlayTrigger, Tooltip, Modal, Form, FormControl, Tabs, Tab } from "react-bootstrap";
import '../index.css';
import './mpd.css';
import { renderBackground, RenderProjectPreferences, renderEmail, renderStudyStatus, getPreferenceForProjectIndex, renderWorkingStyle, renderBestWorkingStyle, renderBestWorkingStylesTeam, renderProjectPreferencePoints, studentStagingObjectToMap, renderCommentsSmall } from '../Student/student.commons';
import { getLabels, renderBackgroundTag, renderConsultingExpTag, renderLabelTag, renderStudyStatusTag, renderTechTag } from '../Student/student.tags';
import { Drawer } from '../Drawer/drawer'
import StudentProfile from "../Student/student.profile";
import { BsFillBarChartFill } from "react-icons/bs";
import { checkStageAndIndex, getMaxRestriction, separateStages } from "../utilities/team.common";
import ReactDOMServer from 'react-dom/server';

class MPDmatching extends Component {
    constructor() {
        super();
        this.state = {
            isLoaded: false,
            students: [],
            projects: [],
            projectAvgScores: [],
            overallAvgScore: 0,
            availableFilter: [],
            matching: null,
            studentsInTeam: [],
            drawerOpen: false,
            drawerStudent: null,
            drawerTeam: null,
            drawerStudentIndex: 0,
            draggedStudentIndex: 0,
            draggedProjectIndex: 0,
            rankingTeamIndex: null,
            stagingId: null,
            createMessage: false,
            subject: "",
            email: "",
            separatedStages: null,
            maxRestriction: null,
            showLastTeam: false,
            activeKey: 0,
            caOverlap: {},
            tfOverlap: {}
        };
        this.updateProjectLabels = this.updateProjectLabels.bind(this);
        this.getPreviousDrawerStudent = this.getPreviousDrawerStudent.bind(this);
        this.getNextDrawerStudent = this.getNextDrawerStudent.bind(this);
        this.getStudentsPerProjectIndex = this.getStudentsPerProjectIndex.bind(this)
    }

    createEmailText() {
        this.setState({
            subject: this.state.matching.staging.stagingName + " - It's a match",
            email: this.state.matching.staging.stagingType === "TRENDSEMINAR" ? "Dear Student,\nwe are happy to announce that you will be joining %PROJECT%." : this.state.matching.staging.stagingType === "TASKFORCE & BUDDIES" ? "Dear Student,\nwe are happy to announce your Taskforce, CA-Buddy and Professor Mentor\n\nTaskforce: %TF%\nCA-Buddy: %CA%\nProfessor Mentor: %PROF%" : "Dear Student,\nwe are happy to announce that you will be joining Team %PROJECT%."
        })
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_API_URL + "/matchingById/" + this.props.match.params.id, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
            })
        })
            .then(res => res.json())
            .then((result) => {
                console.log(result)
                let mappedStudents = studentStagingObjectToMap(result.staging.students)
                let separatedStagesResult = null
                if (result.staging.stagingType === "TRENDSEMINAR" || result.staging.stagingType === "TASKFORCE & BUDDIES") {
                    separatedStagesResult = separateStages(result.staging.projects)
                }
                this.setState({
                    stagingId: result.staging._id,
                    students: mappedStudents,
                    projects: result.staging.projects,
                    availableFilter: result.staging.restrictions,
                    matching: result,
                    separatedStages: separatedStagesResult,
                });
                this.calculateData(result.studentsInTeam);
                this.createEmailText()
                this.checkStudentCaOverlap()
                let maxRestriction = getMaxRestriction(this.state.matching.staging.students, this.state.matching.staging._id)
                this.setState({ maxRestriction })
            },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    calculateData(studentsInTeam) {
        if (!studentsInTeam) {
            let studentsInTeam = [];
            for (let i = 0; i < this.state.projects.length; i++) {
                let students = this.getStudentsPerProjectIndex(i);
                studentsInTeam.push(students);
            }
            this.setState({ studentsInTeam })
        } else {
            for (let i = 0; i < studentsInTeam.length; i++) {
                studentsInTeam[i] = studentStagingObjectToMap(studentsInTeam[i])
            }
            this.setState({ studentsInTeam })
        }
        this.calculateProjectScores();
    }

    calculateProjectScores() {
        this.checkStudentCaOverlap()
        let projectAvgScores = [];
        let overallScore = 0;
        let numStudents = 0;
        for (let teamCounter = 0; teamCounter < this.state.studentsInTeam.length; teamCounter++) {
            let score = 0;
            for (let studentCounter = 0; studentCounter < this.state.studentsInTeam[teamCounter].length; studentCounter++) {
                let student = this.state.studentsInTeam[teamCounter][studentCounter];
                if (student.stagings == null || student.stagings.get(this.state.stagingId) == null) {
                    let projectAvgScoresNA = this.state.studentsInTeam.map(() => {
                        return "N.A."
                    })
                    this.setState({ projectAvgScores: projectAvgScoresNA, overallAvgScore: "N.A.", isLoaded: true })
                    return
                }
                let studentScore = getPreferenceForProjectIndex(student, teamCounter, this.state.stagingId, this.state.projects)
                score += studentScore
                overallScore += studentScore
                numStudents++
            }
            projectAvgScores.push(Math.round(100 * score / this.state.studentsInTeam[teamCounter].length) / 100);
        }
        overallScore = Math.round(100 * (overallScore / numStudents)) / 100
        this.setState({ projectAvgScores: projectAvgScores, overallAvgScore: overallScore, isLoaded: true })
    }

    getStudentsPerProjectIndex(index) {
        let students = [];
        let stagingId = this.state.stagingId
        for (let i = 0; i < this.state.matching.matrix[index].length; i++) {
            if (this.state.matching.matrix[index][i] === 1) {
                students.push(this.state.students[i]);
            }
        }
        let projects = this.state.projects
        students.sort(function (a, b) { return getPreferenceForProjectIndex(a, index, stagingId, projects) - getPreferenceForProjectIndex(b, index, stagingId, projects) })
        return students;
    }


    async updateMatching(matching) {
        let response = await fetch(process.env.REACT_APP_API_URL + '/existingMatching', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
            },
            body: JSON.stringify(
                matching
            )
        })
        let newMatching = await response.json();
        return newMatching;
    }

    dropBeforeStudent(event, studentIndex, projectIndex) {
        event.preventDefault();
        if (this.state.matching.staging.stagingType === "TRENDSEMINAR" || this.state.matching.staging.stagingType === "TASKFORCE & BUDDIES") {
            let prevTeam = checkStageAndIndex(this.state.draggedProjectIndex, this.state.separatedStages)
            let newTeam = checkStageAndIndex(projectIndex, this.state.separatedStages)
            if (prevTeam["stage"] !== newTeam["stage"]) {
                return null
            }
        }
        let studentsInTeam = this.state.studentsInTeam;
        let selectedStudent = studentsInTeam[this.state.draggedProjectIndex][this.state.draggedStudentIndex]
        studentsInTeam[this.state.draggedProjectIndex].splice(this.state.draggedStudentIndex, 1)
        studentsInTeam[projectIndex].splice(studentIndex, 0, selectedStudent)
        let matching = this.state.matching
        matching.studentsInTeam = this.state.studentsInTeam
        this.updateMatching(matching)
        this.setState({ studentsInTeam: studentsInTeam })
        this.calculateProjectScores()
    }

    getTeamsOfStudent(student) {
        let teamList = []
        for (let teamIndex = 0; teamIndex < this.state.studentsInTeam.length; teamIndex++) {
            for (let studentIndex = 0; studentIndex < this.state.studentsInTeam[teamIndex].length; studentIndex++) {
                if (this.state.studentsInTeam[teamIndex][studentIndex]._id === student._id) {
                    teamList.push(teamIndex)
                }
            }
        }
        return teamList
    }

    getCaOfTaskforce(project) {
        let tfRestriction = this.state.matching.staging.restrictions.find(r => r.restrictionType === "TASKFORCE")
        if (tfRestriction) {
            let option = tfRestriction.restriction.options.find(e => e._id.toString() === project._id.toString())
            let optionIndex = tfRestriction.restriction.options.indexOf(option)
            if (optionIndex >= 0) {
                return tfRestriction.restriction.cas[optionIndex]
            }
        }
        return null
    }

    allowDropBeforeStudent(event, studentIndex, projectIndex) {
        event.preventDefault();
    }

    studentDrag(event, studentIndex, projectIndex) {
        this.setState({ draggedStudentIndex: studentIndex, draggedProjectIndex: projectIndex })
    }

    getNextDrawerStudent() {
        let groupIndex = this.state.drawerTeam;
        let students = this.state.studentsInTeam[groupIndex];
        let index = this.state.drawerStudentIndex + 1;
        if (index >= students.length) {
            index = 0;
            groupIndex++;
            if (groupIndex >= this.state.studentsInTeam.length) {
                groupIndex = 0;
            }
        }
        this.setState({ drawerStudent: this.state.studentsInTeam[groupIndex][index], drawerStudentIndex: index, drawerTeam: groupIndex });
    }

    getPreviousDrawerStudent() {
        let groupIndex = this.state.drawerTeam;
        let index = this.state.drawerStudentIndex - 1;
        if (index < 0) {
            groupIndex--;
            if (groupIndex < 0) {
                groupIndex = this.state.studentsInTeam.length - 1
            }
            index = this.state.studentsInTeam[groupIndex].length - 1;
        }
        this.setState({ drawerStudent: this.state.studentsInTeam[groupIndex][index], drawerStudentIndex: index, drawerTeam: groupIndex });
    }

    updateProjectLabels(updatedRestriction) {
        fetch(process.env.REACT_APP_API_URL + "/matchingById/" + this.props.match.params.id, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    let mappedStudents = studentStagingObjectToMap(result.staging.students)
                    this.setState({
                        students: mappedStudents,
                        projects: result.staging.projects,
                        availableFilter: result.staging.restrictions,
                        matching: result,
                    });
                    //this.calculateData();

                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    updateRankingIndex(projectIndex) {
        if (projectIndex === this.state.rankingTeamIndex) {
            this.setState({ rankingTeamIndex: null })
        } else {
            this.setState({ rankingTeamIndex: projectIndex })
        }
    }

    renderStudentsInProject(projectIndex) {
        return (
            <div>
                {this.state.studentsInTeam[projectIndex].map((student, studentIndex) =>
                    <Card key={student._id} className="mb-1" draggable={!this.state.matching.staging.archived} onDragStart={(event) => {
                        this.studentDrag(event, studentIndex, projectIndex)
                    }} onDragOver={(event) => { this.allowDropBeforeStudent(event, studentIndex, projectIndex) }} onDrop={(event) => { this.dropBeforeStudent(event, studentIndex, projectIndex) }}>
                        <Accordion>
                            <Accordion.Toggle className="fullSize text-left blackFont" as={Button} variant="link" eventKey="0">
                                <Row >
                                    <Col xs={1} className="pl-2 pr-0">
                                        {renderBestWorkingStyle(student, this.state.stagingId)}
                                    </Col>
                                    <Col xs={1} className="p-0">
                                        {this.state.rankingTeamIndex !== null ? <div className="preferenceCircle"><b className="centeredPreference">{getPreferenceForProjectIndex(student, this.state.rankingTeamIndex, this.state.stagingId, this.state.projects)}</b></div> :
                                            this.state.showLastTeam && this.calculateLastTeam(student, projectIndex) !== -1 ? <div className="preferenceCircle"><b className="centeredPreference">{this.calculateLastTeam(student, projectIndex) + 1}</b></div> :
                                                <Image src={student.profilePicture} roundedCircle fluid />
                                        }
                                    </Col>
                                    <Col className="mt-auto mb-auto">
                                        {student.firstName} {student.lastName} {this.state.matching.staging.stagingType.toUpperCase() === "TRENDSEMINAR" ? renderTechTag(student, this.state.stagingId) : renderBackgroundTag(student, this.state.stagingId)}{renderStudyStatusTag(student, this.state.stagingId)}{renderConsultingExpTag(student, this.state.stagingId)}{renderLabelTag(student, this.state.stagingId)}
                                    </Col>
                                </Row>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body> <div>
                                    {renderEmail(student)}
                                    {renderBackground(student, this.state.stagingId)}
                                    {renderStudyStatus(student, this.state.stagingId)}
                                    {renderCommentsSmall(student, this.props.match.params.id)}
                                    {renderWorkingStyle(student, this.state.stagingId)}
                                    <RenderProjectPreferences student={student} stagingId={this.state.stagingId} stagingType={this.state.matching.staging.stagingType} />
                                    <hr />
                                    <Row >
                                        <Col><b>Assigned Labels:</b></Col>
                                    </Row>
                                    {renderLabelTag(student, this.state.stagingId)}
                                    <hr />
                                    <Row className="mt-3">
                                        <Col xs={10} className="ml-auto mr-auto">
                                            <Button onClick={() => { this.setState({ drawerOpen: true, drawerStudent: student, drawerStudentIndex: studentIndex, drawerTeam: projectIndex }) }} className="fullSize">
                                                <b>Full Profile</b>
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Accordion>
                    </Card>)
                }
            </div>
        )
    }


    sendEmails() {
        let studentsToSend = []
        let emailsPerTeam = []
        let coreEmail = { subject: "Overview Taskforce & Buddy Matching", receiver: "core@cdtm.de", email: "Results of Matching", html: [] }
        let tableContent = []
        if (this.state.matching.staging.stagingType === "TASKFORCE & BUDDIES") {
            for (let i = 0; i < this.state.students.length; i++) {
                tableContent.push({ student: this.state.students[i].firstName + " " + this.state.students[i].lastName })
                let teams = this.getTeamsOfStudent(this.state.students[i])
                let subjectString = this.state.subject
                let emailString = this.state.email
                for (let j = 0; j < teams.length; j++) {
                    let stage = checkStageAndIndex(teams[j], this.state.separatedStages)['stage']
                    subjectString = subjectString.replace("%" + stage + "%", this.removeStageFromTeamName(teams[j]))
                    emailString = emailString.replace("%" + stage + "%", this.removeStageFromTeamName(teams[j]))
                    tableContent[i][stage] = this.removeStageFromTeamName(teams[j])
                }
                emailsPerTeam.push({
                    subject: subjectString, email: emailString, receiver: this.state.students[i]
                })
            }
            let html = []
            tableContent.map(element => {
                html.push(<tr>
                    {Object.keys(element).map(e => {
                        return <td>{element[e]}</td>
                    })}
                </tr>)
                return null
            })
            let tableHeader = this.state.separatedStages['stages'].map(e => {
                return <th>{e === "PROF" ? "Professor Mentor" : e === "CA" ? "CA Buddy" : "TF" ? "Taskforce" : e}</th>
            })
            coreEmail['html'] = (<table><tr><th>Student</th>{tableHeader}</tr>{html}</table>)
            coreEmail['html'] = ReactDOMServer.renderToStaticMarkup(coreEmail['html'])
            coreEmail['html'] = "<html><head><style>table, td, th {border: 1px solid black;text-align:left;padding-left: 5px;padding-right: 20px}table {border-collapse: collapse;}</style></head><body><p>Here is the result for the matching of Taskforce, CA Buddy and Professor Mentor</p>" + coreEmail['html'] + "</body></html>"
        } else {
            emailsPerTeam = this.state.projects.map((project, index) => {
                if (this.state.matching.staging.stagingType === "TRENDSEMINAR") {
                    if (parseInt(checkStageAndIndex(index, this.state.separatedStages)['stageIndex']) !== parseInt(this.state.activeKey)) {
                        return null
                    } else {
                        return {
                            subject: this.state.subject.replace("%PROJECT%", this.removeStageFromTeamName(index)), email: this.state.email.replace("%PROJECT%", this.removeStageFromTeamName(index)), receiver: this.state.studentsInTeam[index]
                        }
                    }
                }
                return {
                    subject: this.state.subject.replace("%PROJECT%", this.removeStageFromTeamName(index)), email: this.state.email.replace("%PROJECT%", this.removeStageFromTeamName(index)), receiver: this.state.studentsInTeam[index]
                }
            })
        }
        let emails = emailsPerTeam.reduce((result, emails) => { if (emails) { return result.concat(emails) } else { return result } }, [])
        fetch(process.env.REACT_APP_API_URL + '/sendMatchingResults/' + this.props.match.params.id, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
            },
            body: JSON.stringify({ studentsToSend: studentsToSend, emails: emails, coreEmail: this.state.matching.staging.stagingType === "TASKFORCE & BUDDIES" ? coreEmail : null })
        }).then((result) => {
            return result.json()
        }).then((result) => {
            this.props.history.push('/')
        },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )

    }

    renderMaxRestrictions(restriction) {
        let html = []
        if (restriction.restriction && restriction.restriction.options && this.state.maxRestriction && this.state.maxRestriction[restriction._id]) {
            html.push(<Row xs={12} className="ml-1 mr-1 mt-2">
                <Col ><b>{restriction.restrictionName}</b></Col>
            </Row>)
            for (let i = 0; i < restriction.restriction.options.length; i++) {
                html.push(<Row xs={12} className="ml-1 mr-1">
                    <Col >{restriction.restriction.options[i]}</Col>
                    <Col className="text-right" >{this.state.maxRestriction[restriction._id][restriction.restriction.options[i]] ? this.state.maxRestriction[restriction._id][restriction.restriction.options[i]] : 0}</Col>
                </Row>)
            }
        }
        return html
    }

    getProjectOverlaps(project, projectIndex) {
        let counts = {}
        let priorTeammates = {}
        let priorTeams = this.state.studentsInTeam[projectIndex].map(student => {
            return this.getTeamsOfStudent(student)
        })
        priorTeams.forEach((element, elementIndex) => {
            element.forEach(e => {
                if (projectIndex !== e) {
                    counts[e.toString()] = (counts[e.toString()] || 0) + 1
                    if (!priorTeammates[e.toString()]) {
                        priorTeammates[e.toString()] = []
                    }
                    priorTeammates[e.toString()].push(this.state.studentsInTeam[projectIndex][elementIndex])
                }
            })
        });

        let projectString = []
        Object.keys(priorTeammates).forEach(key => {
            if (priorTeammates[key].length >= 2) {
                projectString.push(<div><b>{this.state.projects[parseInt(key)].projectName}</b></div>)
                let peopleString = ""
                priorTeammates[key].forEach((e, elementIndex) => {
                    if (elementIndex !== 0) {
                        peopleString += " - "
                    }
                    peopleString += e.lastName
                })
                projectString.push(<div>{peopleString}</div>)
            }
        })
        return { 'priorTeams': priorTeammates, 'projectString': projectString, 'priorTeammates': priorTeammates, 'counts': counts, 'overlap': Object.values(counts).reduce((element, result) => element + result - 1, 0) }

    }

    renderOverlapOfTeams() {
        let html = []
        this.state.projects.map((project, index) => {
            if (this.state.separatedStages && checkStageAndIndex(index, this.state.separatedStages)['teamIndexInStage'] === 0) {
                html.push(<Col xs={12}><b className="ml-1 mr-1 mt-2">{checkStageAndIndex(index, this.state.separatedStages)['stage'].toUpperCase()}</b></Col>)
            }

            let analysed = this.getProjectOverlaps(project, index)

            html.push(
                <Row xs={12} className="ml-1 mr-1" >
                    <Col>{this.state.separatedStages && this.removeStageFromTeamName(index)}</Col>
                    <OverlayTrigger
                        key={index}
                        placement="left"
                        overlay={
                            <Tooltip id={`tooltip-${index}`}>
                                {analysed['projectString']}
                            </Tooltip>
                        }
                    >
                        <Col className="text-right" >{analysed['overlap']}</Col>
                    </OverlayTrigger>
                </Row>)
            return null

        }
        )
        return html
    }

    calculateLastTeam(student, projectIndex) {
        let infos = checkStageAndIndex(projectIndex, this.state.separatedStages)
        if (infos['stageIndex'] > 0) {
            return checkStageAndIndex(this.getTeamsOfStudent(student)[infos['stageIndex'] - 1], this.state.separatedStages)['teamIndexInStage']
        } else {
            return -1
        }
    }

    toggleShowLastTeam() { this.setState({ showLastTeam: !this.state.showLastTeam }) }


    renderTabsTrendseminar() {
        let html = []
        let teamsPerStage = {}
        for (let i = 0; i < this.state.projects.length; i++) {
            if (!teamsPerStage[checkStageAndIndex(i, this.state.separatedStages)['stage']])
                teamsPerStage[checkStageAndIndex(i, this.state.separatedStages)['stage']] = []
            teamsPerStage[checkStageAndIndex(i, this.state.separatedStages)['stage']].push(this.state.projects[i])
        }
        html.push(<Tabs onSelect={selectedKey => { this.setState({ activeKey: selectedKey }); }} id="uncontrolled-tab-example">{Object.keys(teamsPerStage).map((stageKey, stageIndex) => {
            return (
                <Tab eventKey={stageIndex} title={stageKey} >
                    <Form.Group>
                        <Form.Label>Subject: </Form.Label>
                        <FormControl
                            disabled={this.state.matching.staging.archived}
                            value={this.state.subject}
                            onChange={e => {
                                this.setState({ subject: e.target.value })
                            }}
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Email: </Form.Label>
                        <FormControl
                            disabled={this.state.matching.staging.archived}
                            value={this.state.email}
                            onChange={e => {
                                this.setState({ email: e.target.value })
                            }}
                            as="textarea"
                            rows={16}
                        />
                    </Form.Group>
                </Tab >
            )
        })
        }</Tabs >)
        return html
    }

    removeStageFromTeamName(projectIndex) {
        let project = this.state.projects[projectIndex]
        if (this.state.separatedStages) {
            return project.projectName.substr(checkStageAndIndex(projectIndex, this.state.separatedStages)['stage'].length + 3, project.projectName.length)
        }
        return project.projectName
    }

    checkStudentCaOverlap() {

        let caOverlap = {}
        let tfOverlap = {}
        for (let projectIndex = 0; projectIndex < this.state.projects.length; projectIndex++) {
            let project = this.state.projects[projectIndex]
            let students = this.state.studentsInTeam[projectIndex]
            let ca = this.getCaOfTaskforce(project)
            if (!ca) {
                continue
            }
            let caProject = this.state.projects.find(p => p._id.toString() === ca._id.toString())
            let overlaps = this.state.studentsInTeam[this.state.projects.indexOf(caProject)].reduce((r, s) => {
                if (students.find(e => e._id === s._id)) {
                    r.push(s)
                    return r
                }
                return r
            }, [])
            if (overlaps.length && overlaps.length > 0) {
                if (!(ca._id in caOverlap)) {
                    caOverlap[ca._id] = []
                }
                caOverlap[ca._id] = caOverlap[ca._id].concat(overlaps)
                if (!(project._id in tfOverlap)) {
                    tfOverlap[project._id] = []
                }
                tfOverlap[project._id] = tfOverlap[project._id].concat(overlaps)
            }
        }
        this.setState({ caOverlap, tfOverlap })
        console.log(this.state.tfOverlap)
        console.log(this.state.caOverlap)
    }

    render() {
        return (
            <div>
                {this.state.isLoaded && <div>
                    <Drawer open={this.state.drawerOpen} width={"50%"} onDrawerClose={() => this.setState({ drawerOpen: false })} onPrevious={() => this.getPreviousDrawerStudent()} onNext={() => this.getNextDrawerStudent()}>
                        <div className="incectedContent">{this.state.drawerStudent !== null && <StudentProfile labelMap={getLabels(this.state.students, this.state.stagingId)} student={this.state.drawerStudent} updateProjectLabels={this.updateProjectLabels} stagingId={this.state.stagingId} stagingType={this.state.matching.staging.stagingType} />}</div>
                    </Drawer>
                    <h2 className="pt-2 mb-2 ml-3">{this.state.matching.staging.stagingName} - Rearrange & Notify</h2>
                    <Row className="m-2">
                        <Col className="mt-2 matchingContent">
                            <Row>
                                {this.state.projects.map((project, index) =>
                                    <>
                                        {this.state.separatedStages && (this.state.matching.staging.stagingType === "TRENDSEMINAR" || this.state.matching.staging.stagingType === "TASKFORCE & BUDDIES") && checkStageAndIndex(index, this.state.separatedStages)['teamIndexInStage'] === 0 ? <Col xs={12}><h5 className="mt-3">{checkStageAndIndex(index, this.state.separatedStages)['stage'].toUpperCase()}</h5></Col>
                                            : null}
                                        <Col xs={12} sm={12} md={6} lg={6} xl={4} className="mb-4" key={project._id}>
                                            <Card>
                                                {this.state.separatedStages && this.state.matching.staging.stagingType.toUpperCase() === "TRENDSEMINAR" ?
                                                    <h4 className="mt-2 ml-3">{this.removeStageFromTeamName(index)}
                                                        {checkStageAndIndex(index, this.state.separatedStages)['stageIndex'] > 0 && <OverlayTrigger
                                                            key={project._id}
                                                            placement={"top"}
                                                            overlay={
                                                                <Tooltip id={`tooltip-${project._id}`}>
                                                                    When activated replaces the profile picture with the team index the person has been in the previous stage. For <b>{checkStageAndIndex(index, this.state.separatedStages)['stage']}</b> it displays the team index of stage <b>{this.state.separatedStages['stages'][checkStageAndIndex(index, this.state.separatedStages)['stageIndex'] - 1]}</b>
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button onClick={() => { this.toggleShowLastTeam() }} variant="light" className="border-0" active={this.state.showLastTeam}>
                                                                <BsFillBarChartFill />
                                                            </Button>
                                                        </OverlayTrigger>}
                                                    </h4>
                                                    :
                                                    <h4 className="mt-2 ml-3">{this.removeStageFromTeamName(index)}
                                                        <OverlayTrigger
                                                            key={project._id}
                                                            placement={"top"}
                                                            overlay={
                                                                <Tooltip id={`tooltip-${project._id}`}>
                                                                    When activated replaces the profile picture with the persons preference for project <b>{project.projectName}</b>
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button onClick={() => { this.updateRankingIndex(index) }} variant="light" className="border-0" active={this.state.rankingTeamIndex === index}>
                                                                <BsFillBarChartFill />
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </h4>
                                                }
                                                {this.state.matching.staging.stagingType === "TRENDSEMINAR" ?
                                                    <Row xs={12} className="ml-1 mr-1 mb-2" >
                                                        <Col xs={10}>Overlaps other stages:</Col>
                                                        <OverlayTrigger
                                                            key={index}
                                                            placement="left"
                                                            overlay={
                                                                <Tooltip id={`tooltip-${index}`}>
                                                                    {this.getProjectOverlaps(project, index)['projectString']}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Col className="text-right" xs={2}>{this.getProjectOverlaps(project, index)['overlap']}</Col>
                                                        </OverlayTrigger>
                                                    </Row> :
                                                    <>
                                                        <Row xs={12} className="ml-1 mr-1 mb-2">
                                                            <Col xs={8}>Avg. Person Preference:</Col>
                                                            <Col className="text-right">{this.state.projectAvgScores[index]}</Col>
                                                        </Row>
                                                        {this.getCaOfTaskforce(project) && <Row xs={12} className="ml-1 mr-1 mb-2">
                                                            <Col xs={4}>CA:</Col>
                                                            <Col className="text-right">{this.getCaOfTaskforce(project)['projectName'].replace("CA - ", "")}</Col>
                                                        </Row>}
                                                        {this.state.tfOverlap[project._id] && <Row xs={12} className="ml-1 mr-1 mb-2 red">
                                                            <Col xs={8}>CA-Buddy Overlap:</Col>
                                                            <OverlayTrigger
                                                                key={project._id}
                                                                placement={"right"}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-${project._id}`}>
                                                                        {this.state.tfOverlap[project._id].map(o => <div>{o.firstName + " " + o.lastName}</div>)}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <Col className="text-right">{this.state.tfOverlap[project._id].length}</Col>
                                                            </OverlayTrigger>
                                                        </Row>}
                                                        {this.state.caOverlap[project._id] && <Row xs={12} className="ml-1 mr-1 mb-2 red">
                                                            <Col xs={8}>CA-Buddy Overlap:</Col>
                                                            <OverlayTrigger
                                                                key={project._id}
                                                                placement={"right"}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-${project._id}`}>
                                                                        {this.state.caOverlap[project._id].map(o => <div>{o.firstName + " " + o.lastName}</div>)}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <Col className="text-right">{this.state.caOverlap[project._id].length}</Col>
                                                            </OverlayTrigger>
                                                        </Row>}
                                                    </>
                                                }
                                                <Row xs={12} className="ml-1 mr-1 mb-2">
                                                    <Col xs={12}>{renderBestWorkingStylesTeam(this.state.studentsInTeam[index], this.state.stagingId)}</Col>
                                                </Row>
                                                <hr />
                                                <Row xs={12} className="ml-1 mr-1 mb-2">
                                                    <Col>{this.renderStudentsInProject(index)}</Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </Col>
                        <Col xs={12} sm={6} md={5} lg={4} xl={3} className="mt-2">
                            <Card >
                                <h3 className="mt-2 ml-3">Statistics</h3>
                                <hr />
                                {this.state.matching.staging.stagingType === "MPD" || this.state.matching.staging.stagingType === "ELAB" || this.state.matching.staging.stagingType === "TASKFORCE & BUDDIES" ?
                                    <>
                                        <Row xs={12} className="ml-1 mr-1">
                                            <Col xs={8}><b>Avg. Person Preference:</b></Col>
                                            <Col className="text-right"><b>{this.state.overallAvgScore}</b></Col>
                                        </Row>
                                        <hr />
                                        <Row xs={12} className="ml-1 mr-1 mb-2">
                                            <Col ><b>Avg. Project Popularity:</b></Col>
                                        </Row>
                                        <div className="statisticsList">
                                            {renderProjectPreferencePoints(this.state.projects, this.state.students, this.state.stagingId)}
                                        </div>
                                        <hr />
                                        <Row xs={12} className="ml-1 mr-1 mb-3">
                                            {renderBestWorkingStylesTeam(this.state.students, this.state.stagingId)}
                                        </Row>
                                    </> : this.state.matching.staging.stagingType === "TRENDSEMINAR" ?
                                        <div className="statisticsListLong">
                                            {this.state.matching.staging.restrictions.map((restriction) =>
                                                this.renderMaxRestrictions(restriction)
                                            )}
                                            <hr />
                                            <div className="mb-5">
                                                {this.renderOverlapOfTeams()}
                                            </div>
                                        </div> : null}
                            </Card>
                        </Col>
                    </Row>
                    <Button disabled={this.state.matching.staging.archived} onClick={() => { this.setState({ createMessage: true }) }} className="float">
                        <b className="my-float">Send results</b>
                    </Button>
                    <Modal show={this.state.createMessage} onHide={(e) => { this.setState({ createMessage: false }) }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Result announcement email</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.matching.staging.stagingType !== "TRENDSEMINAR" ? <Form>
                                <Form.Group>
                                    <Form.Label>Subject: </Form.Label>
                                    <FormControl
                                        disabled={this.state.matching.staging.archived}
                                        value={this.state.subject}
                                        onChange={e => {
                                            this.setState({ subject: e.target.value })
                                        }}
                                        type="text"
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Email: </Form.Label>
                                    <FormControl
                                        disabled={this.state.matching.staging.archived}
                                        value={this.state.email}
                                        onChange={e => {
                                            this.setState({ email: e.target.value })
                                        }}
                                        as="textarea"
                                        rows={16}
                                    />
                                </Form.Group>
                            </Form> : this.renderTabsTrendseminar()}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={(e) => { this.setState({ createMessage: false }) }}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={(e) => { this.sendEmails() }}>
                                Send
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div >}
            </div>
        );
    }
}

export default MPDmatching;
