import React, { Component } from 'react';
import { Col, Row, Form, Button, Card, Modal } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import './survey.css';
import { generateHash } from "../Student/student.commons"


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    borderColor: isDragging ? "#0d6efd" : "lightgray",
    borderWidth: 2,
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    borderColor: "white",
    background: isDraggingOver ? "lightgray" : "white",
});

class StudentPreferenceSurvey extends Component {

    constructor() {
        super();
        this.state = {
            items: [],
            restriction: {},
            staging: null,
            isLoaded: false,
            show: false,
            newStudent: {
                cdtmEmail: "",
            },
            cdtmEmailError: false,
        }
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items
        });
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_API_URL + "/open/stagingByIdUnAuth/" + this.props.match.params.id)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        staging: result,
                    });
                    this.setState({
                        items: this.mapAttributesOfStudent(),
                        isLoaded: true,
                    })
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    mapAttributesOfStudent() {
        let items = this.state.staging.restrictions.reduce((result, restriction) => {
            if (restriction.restrictionType === "PREFERENCE") {
                this.setState({ restriction: restriction });
                result = restriction.restriction.options.map((option, index) => {
                    return {
                        id: option._id,
                        content: option.projectName
                    }
                })
            }
            return result
        }, {})
        return items
    }

    handleClose() {
        this.setState({ show: false })
    };

    renderPreference() {
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <Card
                            className="pt-1 pb-1"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {this.state.items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <Card
                                            className="mt-1 mb-1 ml-1 mr-2 p-2"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            {index + 1}. {item.content}
                                        </Card>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Card>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }

    changeEmail(value) {
        let newStudent = this.state.newStudent;
        newStudent.cdtmEmail = value;
        this.setState({ newStudent, cdtmEmailError: false, formError: false });
    }

    submit() {
        if (this.state.newStudent.cdtmEmail.match("^.*(@cdtm.de)$") === null || generateHash(this.state.newStudent.cdtmEmail).toString() !== this.props.match.params.studentHash) {
            this.setState({ cdtmEmailError: true })
            return
        }
        let preference = this.state.staging.restrictions.reduce((result, restriction) => {
            if (restriction.restrictionType === "PREFERENCE") {
                result = restriction
            }
            return result
        }, {})

        let value = preference.restriction.options.reduce((result, option) => {
            result.push({ project: option, value: this.state.items.findIndex((item) => item.id === option._id) + 1 })
            return result
        }, [])

        fetch(process.env.REACT_APP_API_URL + '/open/StudentAttributeToStaging/' + this.props.match.params.id, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ cdtmEmail: this.state.newStudent.cdtmEmail, attribute: { restrictionSchema: this.state.restriction, value: value } })
        }).then((result) => {
            this.props.history.push('/surveyfinished');
        },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )

    }


    render() {
        return (
            <div className="pb-5">
                <Col xs={12} sm={10} md={8} xl={6} className="ml-auto mr-auto mt-5 card">
                    <Row className="mb-4 mt-2"><Col><h2>Questionnaire</h2></Col></Row>
                    <Row className="mb-2 "><Col><h3>Personal Information:</h3></Col></Row>
                    <Form onSubmit={(event) => { event.preventDefault(); this.submit() }}>
                        <Row>
                            <Col>
                                <Form.Group controlId="cdtmEmail">
                                    <Form.Label><b>CDTM Email:</b></Form.Label>
                                    {this.state.cdtmEmailError && <p className="red">Please use your CDTM Email Address. Make sure it is the Email Address that the Link to this survey was sent to</p>}
                                    <Form.Control value={this.state.newStudent.cdtmEmail} required type="email" placeholder="CDTM Email" onChange={(event) => { this.changeEmail(event.target.value) }} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr />
                        <Row className="mb-2 mt-2"><Col><h3>Projects:</h3></Col></Row>
                        <Row className="mb-2 mt-2"><Col><p>Please order the projects according to your preference:</p></Col></Row>
                        {this.state.isLoaded && this.renderPreference()}
                        <hr />
                        <Button variant="primary" type="submit" className="mb-4 mt-2 float-right" >
                            Submit
                        </Button>
                    </Form>
                </Col>
                <Modal show={this.state.show} onHide={() => { this.handleClose() }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Please check your email address. Maybe you misspelled or haven't filled out the skill survey yet.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.handleClose() }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export { StudentPreferenceSurvey };