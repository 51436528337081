import React, { Component } from "react";
import "bootstrap/js/src/collapse.js";
import { Card, Col, Row, Button, Dropdown, ButtonGroup } from "react-bootstrap";
import '../index.css';
import './mpd.css';


class MPDstagingSelection extends Component {
    constructor() {
        super();
        this.state = {
            stagings: [],
            matchings: [],
            isLoaded: false
        };
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_API_URL + "/stagings", {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        stagings: result
                    });

                },
                (error) => {
                    console.log(error)
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

        fetch(process.env.REACT_APP_API_URL + "/matchings", {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        matchings: result
                    });

                },
                (error) => {
                    console.log(error)
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }



    findMatchingForStaging(stagingId) {
        let matching = this.state.matchings.reduce((result, element) => {
            try {
                if (element.staging && element.staging._id === stagingId) {
                    result = element
                }
            } catch (e) {
                console.log(e)
            }
            return result
        }, null)
        return matching
    }

    calculateSkillSurveyForStaging(staging) {
        let skill = staging.selectedStudents.reduce((result, element) => {
            if (element.skillSurveyStatus === "DONE") {
                result += 1
            }
            return result
        }, 0)
        return skill
    }

    calculatePreferenceSurveyForStaging(staging) {
        let preference = staging.selectedStudents.reduce((result, element) => {
            if (element.preferenceSurveyStatus === "DONE") {
                result += 1
            }
            return result
        }, 0)
        return preference
    }

    checkIfEmailsSent(staging) {
        if (staging.students === null || staging.students.length === 0) return false
        return staging.students.reduce((result, student) => {
            if (student.stagings[staging._id].resultEmailSent === true) {
                return result
            }
            return false
        }, true)
    }

    archiveToggle(stagingIndex) {
        let staging = this.state.stagings[stagingIndex]
        staging.archived = !staging.archived
        this.updateStaging(stagingIndex)
    }

    updateStaging(stagingIndex) {
        fetch(process.env.REACT_APP_API_URL + '/staging', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
            },
            body: JSON.stringify(this.state.stagings[stagingIndex])
        }).then((result) => {
            return result.json()
        }).then((result) => {
            if (result.error) {
                return
            }
            let stagings = this.state.stagings
            stagings[stagingIndex] = result
            this.setState({ stagings })
        },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    }

    deleteStaging(stagingIndex) {
        let matching = this.findMatchingForStaging(this.state.stagings[stagingIndex]._id)
        if (matching !== null) {
            fetch(process.env.REACT_APP_API_URL + '/matching/' + matching._id, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
                },
            }).then((result) => {
                return result.json()
            }).then((result) => {
                if (result.error) {
                    return
                }
                let stagings = this.state.stagings
                stagings.splice(stagingIndex, 1)
                this.setState({ stagings })
            },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        }
        fetch(process.env.REACT_APP_API_URL + '/staging/' + this.state.stagings[stagingIndex]._id, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
            },
        }).then((result) => {
            return result.json()
        }).then((result) => {
            if (result.error) {
                return
            }
            let stagings = this.state.stagings
            stagings.splice(stagingIndex, 1)
            this.setState({ stagings })
        },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    }

    render() {
        return (
            <div >{this.state.isLoaded &&
                <div>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={10} xl={8} className="ml-auto mr-auto">
                            <h2 className="pt-2 mb-2">Projects</h2>
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col xs={12} sm={12} md={12} lg={10} xl={8} className="mt-2 ml-auto mr-auto">
                            <Card >
                                <h3 className="mt-3 mb-3 ml-3">Active Projects</h3>
                                <div className="mb-2">
                                    {this.state.stagings.slice(0).reverse().map((staging, stagingIndex) =>
                                        <Col key={staging._id} data={staging} >
                                            {!staging.archived &&
                                                <Card className="pl-3 mb-4">
                                                    <Row className="mt-3 mb-2">
                                                        <Col xs={9}>
                                                            <h5 className="greyText stagingType">{staging.stagingType}</h5>
                                                            <h4 >{staging.stagingName}</h4>
                                                        </Col>
                                                        <Col xs={2} className="m-auto">
                                                            <Button variant="outline-secondary" block onClick={(e) => { this.archiveToggle(this.state.stagings.length - 1 - stagingIndex) }}>Archive</Button>
                                                        </Col>
                                                        <Col className="pl-0 m-auto">
                                                            <Dropdown as={ButtonGroup}>
                                                                <Dropdown.Toggle className="dropDownButtonWhite" id="dropdown-split-basic" />
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={(e) => { this.deleteStaging(this.state.stagings.length - 1 - stagingIndex) }}>Delete Matching</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </Col>
                                                    </Row>
                                                    <hr className="mb-4 mr-3" />
                                                    <Row>
                                                        <Col>
                                                            {staging.stagingType !== "TASKFORCE & BUDDIES" ? "Skill survey:" : "Skill & Preference survey:"} <span className={this.calculateSkillSurveyForStaging(staging) === staging.selectedStudents.length ? "answerDone" : this.calculateSkillSurveyForStaging(staging) === 0 ? "answerNone" : "answerPending"}>{this.calculateSkillSurveyForStaging(staging)}/{staging.selectedStudents.length}</span>
                                                        </Col>
                                                    </Row>
                                                    {staging.stagingType !== "TRENDSEMINAR" && staging.stagingType !== "TASKFORCE & BUDDIES" &&
                                                        <Row className="mb-2">
                                                            <Col>
                                                                Preference survey: <span className={this.calculatePreferenceSurveyForStaging(staging) === staging.selectedStudents.length ? "answerDone" : this.calculatePreferenceSurveyForStaging(staging) === 0 ? "answerNone" : "answerPending"}>{this.calculatePreferenceSurveyForStaging(staging)}/{staging.selectedStudents.length}</span>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <hr className="mb-4 mr-3" />
                                                    <Row className="mb-2 mr-1">
                                                        <Col>
                                                            <Button variant="outline-primary" block onClick={() => { this.props.history.push('/pending/' + staging._id) }}>Participants & Surveys</Button>
                                                        </Col>
                                                        <Col>
                                                            <Button variant="outline-primary" block onClick={() => { this.props.history.push('/staging/' + staging._id) }}>Configure & Match</Button>
                                                        </Col>
                                                        <Col>
                                                            <Button variant="outline-primary" block disabled={this.findMatchingForStaging(staging._id) === null} onClick={() => { this.props.history.push('/matching/' + this.findMatchingForStaging(staging._id)._id) }}>Rearrange & Notify</Button>
                                                        </Col>
                                                    </Row>
                                                </Card>}
                                        </Col>
                                    )}
                                    {this.state.stagings == null && <p>Active projects will be shown here</p>}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="pb-5">
                        <Col xs={12} sm={12} md={12} lg={10} xl={8} className="mt-2 ml-auto mr-auto">
                            <Card >
                                <h3 className="mt-3 mb-3 ml-3">Archived Projects</h3>
                                <div className="mb-2">
                                    {this.state.stagings != null && this.state.stagings.slice(0).reverse().map((staging, stagingIndex) =>
                                        <Col key={staging._id} data={staging} >
                                            {staging.archived &&
                                                <Card className="pl-3 mb-4">
                                                    <Row className="mt-3 mb-2">
                                                        <Col xs={9}>
                                                            <h5 className="greyText stagingType">{staging.stagingType}</h5>
                                                            <h4 >{staging.stagingName}</h4>
                                                        </Col>
                                                        <Col xs={2} className="m-auto">
                                                            <Button variant="outline-secondary" block onClick={(e) => { this.archiveToggle(this.state.stagings.length - 1 - stagingIndex) }}>Unarchive</Button>
                                                        </Col>
                                                        <Col className="pl-0 m-auto">
                                                            <Dropdown as={ButtonGroup}>
                                                                <Dropdown.Toggle className="dropDownButtonWhite" id="dropdown-split-basic" />
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={(e) => { this.deleteStaging(this.state.stagings.length - 1 - stagingIndex) }}>Delete Matching</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </Col>
                                                    </Row>
                                                    <hr className="mb-4 mr-3" />
                                                    <Row>
                                                        <Col>
                                                            Skill survey: <span className={this.calculateSkillSurveyForStaging(staging) === staging.selectedStudents.length ? "answerDone" : this.calculateSkillSurveyForStaging(staging) === 0 ? "answerNone" : "answerPending"}>{this.calculateSkillSurveyForStaging(staging)}/{staging.selectedStudents.length}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-2">
                                                        <Col>
                                                            Preference survey: <span className={this.calculatePreferenceSurveyForStaging(staging) === staging.selectedStudents.length ? "answerDone" : this.calculatePreferenceSurveyForStaging(staging) === 0 ? "answerNone" : "answerPending"}>{this.calculatePreferenceSurveyForStaging(staging)}/{staging.selectedStudents.length}</span>
                                                        </Col>
                                                    </Row>
                                                    <hr className="mb-4 mr-3" />
                                                    <Row className="mb-2 mr-1">
                                                        <Col>
                                                            <Button variant="outline-primary" block onClick={() => { this.props.history.push('/pending/' + staging._id) }}>Participants & Surveys</Button>
                                                        </Col>
                                                        <Col>
                                                            <Button variant="outline-primary" block onClick={() => { this.props.history.push('/staging/' + staging._id) }}>Configuration</Button>
                                                        </Col>
                                                        <Col>
                                                            <Button variant="outline-primary" block disabled={this.findMatchingForStaging(staging._id) === null} onClick={() => { this.props.history.push('/matching/' + this.findMatchingForStaging(staging._id)._id) }}>Final Matching</Button>
                                                        </Col>
                                                    </Row>
                                                </Card>}
                                        </Col>
                                    )}
                                    {this.state.stagings == null && <p>Active projects will be shown here</p>}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Button onClick={() => { this.props.history.push('/create') }} className="float">
                        <b className="my-float">Create new Matching</b>
                    </Button>
                </div>}
            </div>
        );
    }
}

export default MPDstagingSelection;
