import React from 'react';
import { Col, Image, Row } from "react-bootstrap"

const Loading = (

    <div className="loadingBackground">
        <div >
            <Row xs={12} className="justify-content-md-center mb-3">
                <Col xs={2}>
                    <Image src={require("../assets/4096-3411-max.png")} fluid />
                </Col>
            </Row>
            <Row xs={12} className="justify-content-md-center">
                <Col xs={4}>
                    <div className="loader m-auto" />
                </Col>
            </Row>
        </div>
    </div >
)

const LoadingComponent = () => {


    return (
        <div>{Loading}</div>
    );

}

export { Loading, LoadingComponent }