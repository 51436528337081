import React, { useEffect } from 'react';
import { Col, Row, Badge } from "react-bootstrap";
import '../index.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import { updateStudent, updateRestriction } from './student.commons'

function getTagRestriction(student, stagingId) {
    let result = -1
    try {
        if (!student.stagings || student.stagings.get(stagingId) == null) {
            return result
        }
    } catch (e) {
        return result
    }
    student.stagings.get(stagingId).forEach(attribute => {
        if (attribute.restrictionSchema.restrictionType === "LABEL") {
            result = attribute;
        }
    })
    return result
}

function getLabels(students, stagingId) {
    let labels = new Map()
    for (let studentCounter = 0; studentCounter < students.length; studentCounter++) {
        if (students[studentCounter].stagings == null || students[studentCounter].stagings.get(stagingId) == null) {
            continue
        }
        students[studentCounter].stagings.get(stagingId).forEach(element => {
            if (element.restrictionSchema.restrictionType === "LABEL") {
                for (let valueCounter = 0; valueCounter < element.value.length; valueCounter++) {
                    let value = element.value[valueCounter]
                    if (labels.has(value)) {
                        labels.set(value, labels.get(value) + 1)
                    } else {
                        labels.set(value, 0)
                    }
                }
            }
        });
    }
    return labels
}

function RenderTagInput(props) {
    const [tags, setTags] = React.useState(props.restrictionLabel.value)
    const [currentValue, setCurrentValue] = React.useState("")
    useEffect(() => {
        if (props.restrictionLabel.value !== tags) {
            setTags(props.restrictionLabel.value)
        }
    }, [props.restrictionLabel, tags]);
    let typeahead;
    let lastActionArrow = false;
    let tmpHtml = [];
    tmpHtml.push(
        <div key={Math.random()}>
            <Row>
                <Col><b>Assign tags to student:</b></Col>
            </Row>
            <Row>
                <Col>{renderLabelTagAndRemove(tags, props)}</Col>
            </Row>
            <br />
        </div>
    );
    tmpHtml.push(
        <Row key={props.student._id}>
            <Col>
                <Typeahead
                    placeholder="Type label & enter"
                    id={props.student._id}
                    ref={(ref) => { typeahead = ref }}
                    onChange={(selected) => {
                        let uniq = [...new Set([...props.restrictionLabel.value, ...selected])];
                        let updatedRestriction = [];
                        updatedRestriction = props.restrictionLabel.restrictionSchema;
                        updatedRestriction.restriction.options = [...new Set(Array.from(props.labelMap.keys()).concat(uniq))];
                        props.restrictionLabel.value = [...new Set(uniq)];
                        updateStudent(props.student)
                        updateRestriction(updatedRestriction).then((result) => {
                            if (props.updateProjectLabels != null) {
                                props.updateProjectLabels(result);
                            }
                        });
                        setTags(uniq);
                        typeahead.clear()
                        typeahead.focus()
                    }}
                    onInputChange={(input) => { setCurrentValue(new Array(input)) }}
                    options={Array.from(props.labelMap.keys())}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' && currentValue !== "" && !lastActionArrow) {
                            let uniq = [...new Set([...props.restrictionLabel.value, ...currentValue])];
                            let updatedRestriction = [];
                            updatedRestriction = props.restrictionLabel.restrictionSchema;
                            updatedRestriction.restriction.options = [...new Set(Array.from(props.labelMap.keys()).concat(currentValue))];
                            props.restrictionLabel.value = [...new Set(uniq)];
                            updateStudent(props.student)
                            updateRestriction(updatedRestriction).then((result) => {
                                if (props.updateProjectLabels != null) {
                                    props.updateProjectLabels(result);
                                }
                            });
                            setTags(uniq);
                            typeahead.clear()
                            typeahead.focus()
                        }
                        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                            lastActionArrow = true
                        } else {
                            lastActionArrow = false
                        }
                    }}
                /></Col>
        </Row>
    );
    return tmpHtml;
}

function renderLabelTagAndRemove(tags, props) {
    return tags.map((tag, index) => {
        return (
            <Badge className="labelBadge ml-1 mouseDefault" key={index}>
                {tag} <b className="mouseClickable" onClick={() => {
                    tags.splice(index, 1);
                    let uniq = [...new Set(tags)];
                    let updatedRestriction = [];
                    updatedRestriction = props.restrictionLabel.restrictionSchema;
                    updatedRestriction.restriction.options = updatedRestriction.restriction.options.concat(tags);
                    updatedRestriction.restriction.options = [...new Set(updatedRestriction.restriction.options)];
                    props.restrictionLabel.value = [...new Set(uniq)];
                    updateStudent(props.student)
                    updateRestriction(updatedRestriction).then((result) => {
                        if (props.updateProjectLabels != null) {
                            props.updateProjectLabels(result);
                        }
                    });
                }}>X</b>
            </Badge>
        )
    }
    )
}

function renderBackgroundTag(student, stagingId) {
    let html = []
    try {
        if (!student.stagings || student.stagings.get(stagingId) == null) {
            return html
        }
    } catch (e) {
        return html
    }
    student.stagings.get(stagingId).forEach((attribute) => {
        if (attribute.restrictionSchema.restrictionType === "BACKGROUND") {
            html.push({
                'O': <Badge key={student._id + attribute._id + Math.random()} className="otherBadge ml-1">Other</Badge>,
                'CS': <Badge key={student._id + attribute._id + Math.random()} className="techBadge ml-1">CS</Badge>,
                'BA': <Badge key={student._id + attribute._id + Math.random()} className="buisBadge ml-1">BA</Badge>,
                'EE': <Badge key={student._id + attribute._id + Math.random()} className="elcBadge ml-1">EE</Badge>,
            }[attribute.value])
        }
    })
    return html
}

function renderTechTag(student, stagingId) {
    let html = []
    try {
        if (!student.stagings || student.stagings.get(stagingId) == null) {
            return html
        }
    } catch (e) {
        return html
    }
    student.stagings.get(stagingId).forEach((attribute) => {
        if (attribute.restrictionSchema.restrictionType === "OPTIONS" && attribute.restrictionSchema.restrictionName === "Tech") {
            html.push(<Badge key={student._id + attribute._id + Math.random()} className={`ml-1 ${attribute.value === "Tech" ? "TechBadge" : attribute.value === "Non-Tech" ? "NoTechBadge" : "defaultBadge"}`}>{attribute.value}</Badge>)
        }
    })
    return html
}

function renderLabelTag(student, stagingId) {
    let html = []
    try {
        if (!student.stagings || student.stagings.get(stagingId) == null) {
            return html
        }
    } catch (e) {
        return html
    }
    student.stagings.get(stagingId).forEach((attribute, index) => {
        if (attribute.restrictionSchema.restrictionType === "LABEL") {
            html.push(attribute.value.map((label) => {
                return (<Badge key={student._id + attribute._id + Math.random()} className="labelBadge ml-1">{label}</Badge>)
            }))
        }
    })
    return html
}

function renderStudyStatusTag(student, stagingId) {
    let html = []
    try {
        if (!student.stagings || student.stagings.get(stagingId) == null) {
            return html
        }
    } catch (e) {
        return html
    }
    student.stagings.get(stagingId).forEach((attribute, index) => {
        if (attribute.restrictionSchema.restrictionType === "STUDYSTATUS") {
            html.push(
                < Badge key={student._id + attribute._id + Math.random()} className={`ml-1 ${attribute.value === "Master" ? "masterBadge" : attribute.value === "Bachelor" ? "bachelorBadge" : "defaultBadge"}`} > {attribute.value}</Badge >)
        }
    })
    return html
}

function renderConsultingExpTag(student, stagingId) {
    let html = []
    try {
        if (!student.stagings || student.stagings.get(stagingId) == null) {
            return html
        }
    } catch (e) {
        return html
    }
    student.stagings.get(stagingId).forEach((attribute, index) => {
        if (attribute.restrictionSchema.restrictionType === "CONSULTINGEXP") {
            html.push(
                < Badge key={student._id + attribute._id + Math.random()} className={`ml-1 ${attribute.value === "Consulting Exp." ? "ConsultingBadge" : attribute.value === "No Consulting Exp." ? "NoConsultingBadge" : "defaultBadge"}`} > {attribute.value}</Badge >)
        }
    })
    return html
}

export { getLabels, renderBackgroundTag, renderStudyStatusTag, RenderTagInput, getTagRestriction, renderLabelTag, renderConsultingExpTag, renderTechTag };