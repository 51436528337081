import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import './drawer.css'
import '../index.css';
import { BsChevronCompactRight, BsChevronCompactLeft, BsX } from "react-icons/bs";

function useKeyboardEvent(key, callback) {
    useEffect(() => {
        const handler = function (event) {
            if (event.key === key) {
                callback()
            }
        }
        window.addEventListener('keydown', handler)
        return () => {
            window.removeEventListener('keydown', handler)
        }
    }, [key, callback])
}

function Drawer(props) {
    const [show, setShow] = useState(false);
    const [animationInProcess, setAnimationInProcess] = useState(false);
    const [slideOut, setSlideOut] = React.useState(0);
    const [slideIn, setSlideIn] = React.useState(0);
    const [drawerSide] = React.useState(1); // 1 = right; 2 = left


    function animationFinished() {
        if (!props.open) { setShow(!show); }
        setSlideOut(0);
        setSlideIn(0);
        setAnimationInProcess(false);
    }

    function close() {
        setAnimationInProcess(true);
        setSlideOut(drawerSide);
    }

    function open() {
        setShow(!show);
        setAnimationInProcess(true);
        setSlideIn(drawerSide);
    }

    useEffect(() => {
        if (props.open !== show && !animationInProcess) {
            if (props.open) {
                open();
            } else {
                close()
            }
        }
    });

    useKeyboardEvent('Escape', () => {
        if (props.open) {
            props.onDrawerClose();
        }
    })

    useKeyboardEvent('ArrowLeft', () => {
        if (props.open) {
            props.onPrevious();
        }
    })

    useKeyboardEvent('ArrowRight', () => {
        if (props.open) {
            props.onNext();
        }
    })

    return (
        <div>
            {show ?
                <div className="drawer">
                    <Row className="full-height" >
                        {drawerSide === 2 && <Col className="drawer-content " xs={props.xs ? props.xs : 8} sm={props.sm ? props.sm : 8} md={props.md ? props.md : 8} lg={props.lg ? props.lg : 8} xl={props.xl ? props.xl : 8} slideout={slideOut} slidein={slideIn} onAnimationEnd={() => { animationFinished() }}>
                            <div >{props.children}</div>
                        </Col>}
                        <Col className="drawer-overlay" slideout={slideOut} slidein={slideIn} onClick={props.onDrawerClose}>
                        </Col>
                        {drawerSide === 1 && <Col className="drawer-content " xs={props.xs ? props.xs : 8} sm={props.sm ? props.sm : 8} md={props.md ? props.md : 8} lg={props.lg ? props.lg : 8} xl={props.xl ? props.xl : 8} slideout={slideOut} slidein={slideIn} onAnimationEnd={() => { animationFinished() }}>
                            <div >
                                {props.onDrawerClose && <BsX className="closeButton mouseClickable" onClick={props.onDrawerClose} />}
                                {props.onPrevious && <BsChevronCompactLeft className="buttonPrevious mouseClickable" onClick={props.onPrevious} />}
                                {props.onNext && <BsChevronCompactRight className="buttonNext mouseClickable" onClick={props.onNext} />}
                                {props.children}
                            </div>
                        </Col>}
                    </Row>
                </div> : null
            }
        </div >
    );
};
export { Drawer };