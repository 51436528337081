import React, { Component } from "react";
import "bootstrap/js/src/collapse.js";
import { Col, Row, Image } from "react-bootstrap";
import '../index.css';
import './student.css';
import "@pathofdev/react-tag-input/build/index.css";
import { RenderLikert, renderBackground, RenderProjectPreferences, renderEmail, renderStudyStatus, renderComments, RenderWorkingStylePychart } from '../Student/student.commons';
import {
    renderBackgroundTag, RenderTagInput, getTagRestriction, renderStudyStatusTag, renderConsultingExpTag, renderTechTag
} from './student.tags';



class StudentProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoaded: false,
            student: null,
            tags: []
        };
        this.updateProjectLabels = this.props.updateProjectLabels.bind(this);
    }

    componentDidMount() {
        if (this.props.student != null) {
            this.setState({
                student: this.props.student,
                isLoaded: true
            });
        }
        else if (this.props.id != null) {
            fetch(process.env.REACT_APP_API_URL + "/studentById/" + this.props.id, {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            student: result,
                            isLoaded: true
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                ).then(() => {
                })
        }
        else if (this.props.match.params.id != null) {
            fetch(process.env.REACT_APP_API_URL + "/studentById/" + this.props.match.params.id, {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            student: result,
                            isLoaded: true
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                ).then(() => {
                })
        }
    }




    render() {
        return (
            <div key={this.props.student._id}>{this.state.isLoaded && <div>
                <Row className="pt-4 mb-2">
                    <Col xs={12} md={10} xl={10} className="pl-5 pt-4 pr-5 m-auto">
                        <Row>
                            <Col xs={6} md={8} lg={4} xl={3} className="m-auto">
                                <div className="container">
                                    <Image src={this.props.student.profilePicture} roundedCircle fluid />
                                    <div className="pychart">
                                        <RenderWorkingStylePychart student={this.props.student} stagingId={this.props.stagingId} />
                                    </div>
                                    <Image className="pychart" src={this.props.student.profilePicture} roundedCircle fluid />
                                </div>
                            </Col>
                            <Col className="ml-4">
                                <h3 className="mt-2 mb-2"><b>{this.props.student.firstName} {this.props.student.lastName}</b>{renderTechTag(this.props.student, this.props.stagingId)}{renderBackgroundTag(this.props.student, this.props.stagingId)}{renderStudyStatusTag(this.props.student, this.props.stagingId)}{renderConsultingExpTag(this.props.student, this.props.stagingId)}</h3>
                                <hr />
                                <Row>
                                    <Col xs={6}>
                                        <b>Personal Information:</b>
                                        {renderEmail(this.props.student, this.props.stagingId)}
                                        {renderBackground(this.props.student, this.props.stagingId)}
                                        {renderStudyStatus(this.props.student, this.props.stagingId)}
                                    </Col>
                                    {this.props.stagingType !== "TASKFORCE & BUDDIES" && <Col>
                                        <RenderProjectPreferences student={this.props.student} stagingId={this.props.stagingId} />
                                    </Col>}
                                </Row>
                                {(getTagRestriction(this.props.student, this.props.stagingId) !== -1) ? <div>
                                    <hr />
                                    <RenderTagInput student={this.props.student} stagingId={this.props.stagingId} labelMap={this.props.labelMap} restrictionLabel={getTagRestriction(this.props.student, this.props.stagingId)} updateProjectLabels={this.props.updateProjectLabels} />
                                </div> : null}
                            </Col>
                        </Row>
                    </Col>
                    {this.props.stagingType === "TASKFORCE & BUDDIES" && <Col xs={12} md={10} xl={10} className="m-auto">
                        <RenderProjectPreferences student={this.props.student} stagingId={this.props.stagingId} />
                    </Col>}
                    <Col xs={12} md={10} xl={10} className="m-auto">
                        <h3 className="pl-3 pt-4 pr-3 mb-2">Attributes:</h3>
                        <RenderLikert student={this.props.student} stagingId={this.props.stagingId} />
                    </Col>
                    <Col xs={12} md={10} xl={10} className="m-auto">
                        <hr />
                        {renderComments(this.props.student, this.props.stagingId)}
                    </Col>
                    <Col xs={12} className="pt-5" ><br /></Col>
                </Row>
            </div>}</div>
        );
    }
}

export default StudentProfile;
