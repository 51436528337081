import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import './survey.css';



class StudentSurveyFinished extends Component {

    constructor() {
        super();
        this.state = {
        }
    }

    render() {
        return (
            <div>
                <Col xs={12} sm={10} md={8} xl={7} className="ml-auto mr-auto mt-5 mb-5 card">
                    <Row className="mb-4 mt-2 "><Col><h2>Thank your for filling out the survey</h2></Col></Row>
                    <Row className="mb-4 mt-2 "><Col>You will be contacted when the assignment process is completed</Col></Row>
                </Col>
            </div>
        );
    }
}

export { StudentSurveyFinished };