import React from 'react';
import { Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import '../index.css';
import StarRatings from 'react-star-ratings';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import './student.css';
import { PieChart } from 'react-minimal-pie-chart';


async function updateStudent(student) {
    student.stagings = Object.fromEntries(student.stagings)
    Object.keys(student.stagings).forEach(stagingKey => {
        student.stagings[stagingKey] = Object.fromEntries(student.stagings[stagingKey])
    })
    let response = await fetch(process.env.REACT_APP_API_URL + '/studentById/' + student._id, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
        },
        body: JSON.stringify(
            student
        )
    })
    let newStudent = await response.json();
    newStudent = singlestudentStagingObjectToMap(newStudent)
    return newStudent;
}

async function updateRestriction(restriction) {
    let response = await fetch(process.env.REACT_APP_API_URL + '/restriction', {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
        },
        body: JSON.stringify(
            restriction
        )
    })
    let newRestriction = await response.json();
    return newRestriction;
}

/* function mapAttributesOfStudent(student) {
    return student.attributes.reduce((result, attribute) => {
        let tmp = result.get(attribute.restrictionSchema.restrictionType)
        tmp = tmp === undefined ? new Array(attribute) : tmp.concat(attribute)
        result.set(attribute.restrictionSchema.restrictionType, tmp)
        return result
    }, new Map())
} */

function hashCode(str) { // java String#hashCode
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 6) - hash);
    }
    return hash;
}

function intToRGB(i) {
    var c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
}

function getColorForKey(key) {
    return key === "ANALYTICAL" ? "003887" : key === "EXPERIMENTAL" ? "fefe40" : key === "RELATIONAL" ? "ff000d" : key === "PRACTICAL" ? "8bc23f" : intToRGB(hashCode(key));
    //return key === "ANALYTICAL" ? "F0503D" : key === "EXPERIMENTAL" ? "F5CA62" : key === "RELATIONAL" ? "715EEB" : key === "PRACTICAL" ? "99DE64" : intToRGB(hashCode(key));
}

function capitalizeOnlyFirst(input) {
    input = input.toLowerCase()
    return input.charAt(0).toUpperCase() + input.slice(1);
}

function mapWorkingStylesToScores(student, stagingId) {
    let result
    try {
        if (!student.stagings || student.stagings.get(stagingId) == null) {
            return null
        }
    } catch (e) {
        return null
    }
    student.stagings.get(stagingId).forEach((attribute) => {
        if (attribute.restrictionSchema.restrictionType === "WORKINGSTYLE") {
            result = attribute
        }
    })
    if (result == null) {
        return null
    }
    let options = new Map(Object.entries(result.restrictionSchema.restriction.options))
    let styleOptions = Array.from(options.values()).reduce((result, element) => { return result.set(element, 0) }, new Map())
    let optionCounter = new Map(styleOptions)
    result.value.forEach(element => {
        optionCounter.set(options.get(element), optionCounter.get(options.get(element)) + 1)
    });
    result.optionCounter = optionCounter
    result.options = styleOptions
    return result
}

function getBestWorkingStyle(student, stagingId) {
    let result = mapWorkingStylesToScores(student, stagingId)
    if (result == null) {
        return null
    }
    let best = []
    let bestScore = 0
    result.optionCounter.forEach((value, key) => {
        if (value > bestScore) {
            bestScore = value;
            best = new Array(key)
        } else if (value === bestScore) {
            best.push(key)
        }
    })
    result.best = best
    return result
}

function renderBestWorkingStylesTeam(students, stagingId) {
    let bestWorkingStyles = students.reduce((r, student) => {
        let result = mapWorkingStylesToScores(student, stagingId)
        if (result == null) {
            return r
        }
        result.optionCounter.forEach((value, key) => {
            if (!(key in r)) {
                r[key] = 0
            }
            r[key] += value
        })
        return r
    }, {})
    if (bestWorkingStyles == null || Object.keys(bestWorkingStyles).length === 0) {
        return []
    }
    let totalPoints = 0
    let lastKey = ""
    let firstKey = ""
    bestWorkingStyles = new Map(Object.entries(bestWorkingStyles))
    bestWorkingStyles.forEach((value, key) => {
        totalPoints += value
        if (value > 0) {
            lastKey = key
            if (firstKey === "") {
                firstKey = key
            }
        }
    })
    let html = []
    bestWorkingStyles.forEach((value, key) => {
        html.push(<OverlayTrigger
            key={key}
            placement={"top"}
            overlay={
                <Tooltip id={key}>
                    {/* {capitalizeOnlyFirst(key)} */}x
                </Tooltip>
            }
        >
            <div id={key} style={{
                backgroundColor: `#${getColorForKey(key)}`, width: `${(value / totalPoints) * 100}%`, display: `${value === 0 ? "none" : ""}`, height: "10px", paddingRight: "1px", borderBottomLeftRadius: `${key === firstKey ? "25px" : "0"}`, borderTopLeftRadius: `${key === firstKey ? "25px" : "0"}`, borderBottomRightRadius: `${key === lastKey ? "25px" : "0"}`, borderTopRightRadius: `${key === lastKey ? "25px" : "0"}`
            }}>
            </div>
        </OverlayTrigger>
        )
    })
    return <Row><Col ><b>Preferred Working Style:</b></Col><Col xs={12}><div className="mt-2 displayFlex">{html}</div></Col></Row>
}



function RenderWorkingStylePychart(props) {
    let workingStyle = mapWorkingStylesToScores(props.student, props.stagingId)
    if (workingStyle == null) {
        return []
    }
    let optionCounter = workingStyle.optionCounter
    let data = []
    optionCounter.forEach((value, key) => {
        data.push({ title: capitalizeOnlyFirst(key), value: value, color: "#" + getColorForKey(key) })
    })
    return (
        <PieChart className="pychartGraph" startAngle={270} key={props.student._id}
            data={data}
        />
    )
}

function renderBestWorkingStyle(student, stagingId) {
    let bestWorkingStyle = getBestWorkingStyle(student, stagingId)
    if (bestWorkingStyle === null || bestWorkingStyle.best === null || bestWorkingStyle.best.length === 0) {
        return []
    }
    let best = bestWorkingStyle.best
    let html = []
    best.forEach((element, index) => {
        html.push(
            <OverlayTrigger
                key={element}
                placement={"top"}
                overlay={
                    <Tooltip id={element}>
                        Preferred Working Style: {capitalizeOnlyFirst(element)}
                    </Tooltip>
                }
            >
                <div id={element} style={{
                    backgroundColor: `#${getColorForKey(element)}`, height: `${(1 / best.length) * 100}%`, width: "5px", borderTopLeftRadius: `${index === 0 ? "50px" : "0"}`, borderTopRightRadius: `${index === 0 ? "50px" : "0"}`, borderBottomRightRadius: `${index === best.length - 1 ? "50px" : "0"}`, borderBottomLeftRadius: `${index === best.length - 1 ? "50px" : "0"}`
                }}>
                </div>
            </OverlayTrigger>
        )
    })
    return html
}

function renderWorkingStyle(student, stagingId) {
    let workingStyle = mapWorkingStylesToScores(student, stagingId)
    if (workingStyle == null) {
        return []
    }
    let optionCounter = workingStyle.optionCounter
    let totalPoints = 0
    let lastKey = ""
    let firstKey = ""
    optionCounter.forEach((value, key) => {
        totalPoints += value
        if (value > 0) {
            lastKey = key
            if (firstKey === "") {
                firstKey = key
            }
        }
    })
    let html = []
    optionCounter.forEach((value, key) => {
        html.push(
            <OverlayTrigger
                key={key}
                placement={"top"}
                overlay={
                    <Tooltip id={key}>
                        {capitalizeOnlyFirst(key)}
                    </Tooltip>
                }
            >
                <div id={key} style={{
                    backgroundColor: `#${getColorForKey(key)}`, width: `${(value / totalPoints) * 100}%`, display: `${value === 0 ? "none" : ""}`, height: "10px", paddingRight: "1px", borderBottomLeftRadius: `${key === firstKey ? "25px" : "0"}`, borderTopLeftRadius: `${key === firstKey ? "25px" : "0"}`, borderBottomRightRadius: `${key === lastKey ? "25px" : "0"}`, borderTopRightRadius: `${key === lastKey ? "25px" : "0"}`
                }}>
                </div>
            </OverlayTrigger>
        )
    })
    return <><Row><Col>Working Style:</Col></Row><Row className="pl-2 pr-2 mt-2">{html}</Row></>
}

function renderProjectPreferencePoints(projects, students, stagingId) {
    let projectPref = projects.map((project, index) => {
        let totalScore = students.reduce((result, student) => {
            return result += getPreferenceForProjectIndex(student, index, stagingId, projects)
        }, 0)
        return [project.projectName, (Math.round((totalScore / students.length) * 100)) / 100]
    })
    return projectPref.sort((obj1, obj2) => {
        if (obj1[1] > obj2[1]) {
            return 1;
        }
        if (obj1[1] < obj2[1]) {
            return -1;
        }

        return 0;
    }).map((pref, index) => {
        return (
            <Row key={index} className="ml-1 mr-1 mb-2">
                <Col>{pref[0]}:</Col>
                <Col className="text-right">{pref[1]}</Col>
            </Row>)
    })
}

function prepareLikertTable(likertData) {
    let data = [];
    data = likertData.map((attribute, index) => {
        return {
            restrictionName: attribute.restrictionSchema.restrictionName,
            restrictionValue: attribute.value
        }
    });
    return data;
}

function starFormatter(cell, row) {
    return (<StarRatings
        rating={cell}
        starRatedColor={cell === 5 ? 'rgb(243, 208, 9)' : cell === 4 ? 'rgb(255, 174, 0)' : 'rgb(121, 121, 121)'}
        numberOfStars={5}
        starDimension="17px"
        starSpacing="2px"
        name='rating'
    />);
}
function prepLikertData(student, stagingId) {
    try {
        if (!student.stagings || student.stagings.get(stagingId) == null) {
            return null
        }
    } catch (e) {
        return null
    }
    let likertArr = [];
    student.stagings.get(stagingId).forEach((attribute) => {
        if (attribute.restrictionSchema.restrictionType === "LIKERT") {
            likertArr.push({ attr: attribute, attrType: attribute.restrictionSchema.restriction.category });
        }
    })
    if (likertArr.length === 0) {
        return null
    }
    let likertObj = {};
    likertObj = likertArr.reduce((prev, curr) => {
        if (curr) {
            if (!likertObj[curr.attrType]) {
                likertObj[curr.attrType] = [];
            }
            likertObj[curr.attrType].push(curr.attr);
            return likertObj;
        }
        return null;
    })
    return likertObj;
}

function RenderLikert(props) {
    let defaultFilter = { number: 3, comparator: '>=' }
    let likertData = prepLikertData(props.student, props.stagingId);
    if (likertData === null) {
        return null
    }
    let likertHTML = [];
    for (const [key, value] of Object.entries(likertData)) {
        let data = prepareLikertTable(value);
        likertHTML.push(
            <div key={key} className="col-12 mt-4">
                <h4>{key}</h4>
                <BootstrapTable data={data} bordered={false}>
                    <TableHeaderColumn isKey dataField='restrictionName' filter={{ type: 'TextFilter', delay: 1000 }}>Attribute</TableHeaderColumn>
                    <TableHeaderColumn dataField='restrictionValue' columnClassName='alignTableContentRight' filter={{
                        type: 'NumberFilter',
                        delay: 100,
                        numberComparators: ['=', '>=', '<'],
                        defaultValue: defaultFilter,

                    }} dataFormat={starFormatter}>Rating</TableHeaderColumn>
                </BootstrapTable>
            </div>)
    }
    return likertHTML;
};


function renderEmail(student) {
    return (
        <Row key={student._id}>
            <Col>Email:</Col>
            <Col className="text-right"><a href={"mailto:" + student.cdtmEmail}>{student.cdtmEmail}</a></Col>
        </Row>)
}

function renderBackground(student, stagingId) {
    let html = []
    try {
        if (!student.stagings || student.stagings.get(stagingId) == null) {
            return html
        }
    } catch (e) {
        return html
    }
    student.stagings.get(stagingId).forEach((attribute) => {
        if (attribute.restrictionSchema.restrictionType === "BACKGROUND") {
            html.push(
                <Row key={attribute.restrictionSchema._id}>
                    <Col>{attribute.restrictionSchema.restrictionName}:</Col>
                    <Col className="text-right">{{
                        'O': <>Other</>,
                        'CS': <>Computer Science</>,
                        'BA': <>Business Administration</>,
                        'EE': <>Electrical Engineering</>,
                    }[attribute.value]}</Col>
                </Row>)
        }
    })
    return html
}

function getPreferenceForProjectIndex(student, projectIndex, stagingId, projects) {
    let value = -1
    try {
        if (!student.stagings || student.stagings.get(stagingId) == null) {
            return value
        }
    } catch (e) {
        return projects.length
    }
    student.stagings.get(stagingId).forEach((attribute) => {
        if (attribute.restrictionSchema.restrictionType === "PREFERENCE" || attribute.restrictionSchema.restrictionType === "LIKERTPREFERENCE" || attribute.restrictionSchema.restrictionType === "TASKFORCE") {
            if (attribute.value) {
                for (let i = 0; i < attribute.value.length; i++) {
                    if (attribute.value[i].project._id.toString() === projects[projectIndex]._id.toString()) {
                        value = attribute.value[i].value
                        if (attribute.restrictionSchema.restrictionType === "LIKERTPREFERENCE" || attribute.restrictionSchema.restrictionType === "TASKFORCE") {
                            value++
                        }
                    }
                }
            }
        }
    })
    if (value === -1) {
        value = projects.length
    }
    return value;
}

function RenderProjectPreferences(props) {
    let preferences = []
    try {
        if (!props.student.stagings || props.student.stagings.get(props.stagingId) == null) {
            return preferences
        }
    } catch (e) {
        return preferences
    }
    props.student.stagings.get(props.stagingId).forEach((attribute) => {
        if (attribute.restrictionSchema.restrictionType === "PREFERENCE" || attribute.restrictionSchema.restrictionType === "LIKERTPREFERENCE" || attribute.restrictionSchema.restrictionType === "TASKFORCE") {
            preferences.push(attribute)
        }
    })
    if (preferences.length === 0) {
        return preferences
    }
    let tmpHtml = [];
    tmpHtml.push(<hr />)
    tmpHtml.push((
        <Row key={props.student._id}>
            <Col><b>Project Preferences:</b></Col>
        </Row>));
    tmpHtml = tmpHtml.concat(
        preferences.map(attribute => {
            if (attribute.value === null) {
                tmpHtml.push(
                    <Row key={props.student._id}>
                        <Col>Not voted yet</Col>
                    </Row>
                )
                return tmpHtml
            }
            let pref = JSON.parse(JSON.stringify(attribute))
            return pref.value.sort((obj1, obj2) => {
                if (obj1.value > obj2.value) {
                    return 1;
                }
                if (obj1.value < obj2.value) {
                    return -1;
                }

                return 0;
            }).map((preference, index) => {
                return (
                    <>
                        {index === 0 && (props.stagingType.toUpperCase() === "TASKFORCE & BUDDIES") ? <Row><Col xs={12}><b>{attribute.restrictionSchema.restrictionName}</b></Col></Row>
                            : null}
                        <Row key={index}>
                            <Col>{attribute.restrictionSchema.restrictionType === "LIKERTPREFERENCE" || attribute.restrictionSchema.restrictionType === "TASKFORCE" ? preference.project.projectName.substring(preference.project.projectName.indexOf(" - ") + 3) : preference.project.projectName}:</Col>
                            <Col xs={2} className="text-right">{attribute.restrictionSchema.restrictionType === "LIKERTPREFERENCE" || attribute.restrictionSchema.restrictionType === "TASKFORCE" ? preference.value + 1 : preference.value}</Col>
                        </Row>
                    </>)
            })
        }
        ))
    return tmpHtml
}

function renderComments(student, stagingId) {
    let tmpHtml = [];
    tmpHtml.push((
        <Row className="mb-1" key={student._id}>
            <Col><h3>Comments:</h3></Col>
        </Row>));
    try {
        if (!student.stagings || student.stagings.get(stagingId) == null) {
            return tmpHtml
        }
    } catch (e) {
        return tmpHtml
    }
    student.stagings.get(stagingId).forEach((attribute, index) => {
        if (attribute.restrictionSchema.restrictionType === "COMMENT") {
            tmpHtml.push(
                <Row className="mb-3" key={index}>
                    <Col xs={12}><b>{attribute.restrictionSchema.restrictionName}</b></Col>
                    <Col>{attribute.value ? attribute.value : '–––'}</Col>
                </Row>)
        }
    })
    return tmpHtml
}

function renderConstantOptions(student, stagingId) {
    let html = []
    try {
        if (!student.stagings || student.stagings.get(stagingId) == null) {
            return html
        }
    } catch (e) {
        return html
    }
    student.stagings.get(stagingId).forEach((attribute) => {
        if (attribute.restrictionSchema.restrictionType === "STUDYSTATUS" || attribute.restrictionSchema.restrictionType === "OPTIONS" || attribute.restrictionSchema.restrictionType === "CONSULTINGEXP") {
            html.push(
                <Row key={student._id + attribute._id + Math.random()}>
                    <Col>{attribute.restrictionSchema.restrictionName}:</Col>
                    <Col className="text-right">{attribute.value}</Col>
                </Row>)
        }
    })
    return html
}

function renderCommentsSmall(student, stagingId) {
    let html = []
    try {
        if (!student.stagings || student.stagings.get(stagingId) == null) {
            return html
        }
    } catch (e) {
        return html
    }
    let comments = []
    student.stagings.get(stagingId).forEach((attribute) => {
        if (attribute.restrictionSchema.restrictionType === "COMMENT") {
            if (attribute.value && attribute.value !== "") {
                comments.push(attribute)
            }
        }
    })

    html.push(
        <Row key={student._id + Math.random()}>
            <Col>Comments:</Col>
            <OverlayTrigger
                key={Math.random()}
                placement={"right"}
                overlay={
                    <Tooltip id={Math.random()}>
                        {comments.map(comment => {
                            return (
                                <div><b>{comment.restrictionSchema.restrictionName}:</b> {comment.value}</div>
                            )
                        })}
                    </Tooltip>
                }
            >
                <Col className="text-right">{comments.length === 0 ? 0 : <b>{comments.length}</b>}</Col>
            </OverlayTrigger>
        </Row>)
    return html
}

function studentStagingObjectToMap(students) {
    students.forEach(student => {
        let stagingMap = new Map()
        if (student.stagings == null) {
            return null
        }
        Object.keys(student.stagings).map(staging => { stagingMap.set(staging, student.stagings[staging]); return null })
        stagingMap.forEach((staging, stagingKey) => {
            let attributeMap = new Map()
            Object.keys(staging).map(attribute => { attributeMap.set(attribute, staging[attribute]); return null })
            stagingMap.set(stagingKey, attributeMap)
            return null
        })
        student.stagings = stagingMap;
    })
    return students
}

function singlestudentStagingObjectToMap(student) {
    let stagingMap = new Map()
    if (student.stagings == null) {
        return null
    }
    Object.keys(student.stagings).map(staging => { stagingMap.set(staging, student.stagings[staging]); return null })
    stagingMap.forEach((staging, stagingKey) => {
        let attributeMap = new Map()
        Object.keys(staging).map(attribute => { attributeMap.set(attribute, staging[attribute]); return null })
        stagingMap.set(stagingKey, attributeMap)
        return null
    })
    student.stagings = stagingMap;
    return student
}

function generateHash(hashable) {
    var hash = 0, i, chr;
    if (hashable.length === 0) return hash;
    for (i = 0; i < hashable.length; i++) {
        chr = hashable.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0;
    }
    return hash;
};

export { generateHash, studentStagingObjectToMap, renderComments, renderCommentsSmall, updateRestriction, updateStudent, RenderLikert, renderBackground, RenderProjectPreferences, renderEmail, renderConstantOptions as renderStudyStatus, getPreferenceForProjectIndex, renderWorkingStyle, renderBestWorkingStyle, RenderWorkingStylePychart, renderBestWorkingStylesTeam, renderProjectPreferencePoints };