import React, { Component } from 'react';
import { Col, Row, Form, Button, ButtonToolbar, ButtonGroup } from "react-bootstrap";
import './survey.css';
import { generateHash } from "../Student/student.commons"



class StudentSkillSurvey extends Component {

    constructor() {
        super();
        this.state = {
            staging: null,
            isLoaded: false,
            restrictionDictionary: null,
            restrictionValues: null,
            newStudent: {
                cdtmEmail: "",
                firstName: "",
                lastName: "",
                profilePicture: "",
                attributes: new Map()
            },
            cdtmEmailError: false,
            backgroundError: false,
            studyStatusError: false,
            consultingExpError: false,
            optionsExpError: false,
            preferenceError: false,
            workingStyleError: false,
            likertError: false,
            formError: false
        }
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_API_URL + "/open/stagingByIdUnAuth/" + this.props.match.params.id)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        staging: result,
                    });
                    let restrictionDictionary = this.mapAttributesOfStudent()
                    this.setState({
                        restrictionDictionary,
                        isLoaded: true,
                    })
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    mapAttributesOfStudent() {
        let attributes = new Map()
        let restrictionDictionary = this.state.staging.restrictions.reduce((result, restriction) => {
            //Set up map for attributes in student according to restriction id
            let value = null
            if (restriction.restrictionType === "LIKERTPREFERENCE" || restriction.restrictionType === "TASKFORCE") {
                value = []
                for (let i = 0; i < restriction.restriction.options.length; i++) {
                    value.push({ project: restriction.restriction.options[i], value: 0 })
                }
            }
            attributes.set(restriction._id, { restrictionSchema: restriction, value: value })
            //Set up map for displaying all Restrictions according to their Restriction Type
            let tmp = result.get(restriction.restrictionType)
            tmp = tmp === undefined ? new Array(restriction) : tmp.concat(restriction)
            result.set(restriction.restrictionType, tmp)
            return result
        }, new Map())
        let newStudent = this.state.newStudent;
        newStudent.attributes = attributes;
        this.setState({ newStudent })
        return restrictionDictionary
    }

    renderBackground() {
        let restrictions = this.state.restrictionDictionary.get("BACKGROUND")
        if (restrictions) {
            return restrictions.map(restriction => {
                return (
                    <Row key={restriction}>
                        <Col>
                            <Form.Group id={restriction._id} controlId={restriction._id}>
                                <Form.Label><b>{restriction.restrictionQuestion}</b></Form.Label>
                                {this.state.backgroundError && <p className="red">Please select your background</p>}
                                <Form.Control defaultValue={-1} required as="select" onChange={(event) => { this.setState({ backgroundError: false, formError: false }); this.changeAttribute(event.target.value, restriction._id) }}>
                                    <option disabled value={-1}> -- select an option -- </option>
                                    {restriction.restriction.options.map(option => {
                                        return {
                                            'O': <option key={option} value={option}>Other</option>,
                                            'CS': <option key={option} value={option}>Computer Science</option>,
                                            'BA': <option key={option} value={option}>Business Administration</option>,
                                            'EE': <option key={option} value={option}> Electrical Engineering</option>,
                                        }[option]
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row >)
            })
        }
    }

    renderStudyStatus() {
        let restrictions = this.state.restrictionDictionary.get("STUDYSTATUS")
        if (restrictions) {
            return restrictions.map(restriction => {
                return (
                    <Row key={restriction}>
                        <Col>
                            <Form.Group id={restriction._id} controlId={restriction._id}>
                                <Form.Label><b>{restriction.restrictionQuestion}</b></Form.Label>
                                {this.state.studyStatusError && <p className="red">Please select your status of studying</p>}
                                <Form.Control defaultValue={-1} required as="select" onChange={(event) => { this.setState({ studyStatusError: false, formError: false }); this.changeAttribute(event.target.value, restriction._id) }}>
                                    <option disabled value={-1}> -- select an option -- </option>
                                    {restriction.restriction.options.map(option => {
                                        return <option key={option}>{option}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>)
            })
        }
    }

    renderOptions() {
        let restrictions = this.state.restrictionDictionary.get("OPTIONS")
        if (restrictions) {
            return restrictions.map(restriction => {
                return (
                    <Row key={restriction}>
                        <Col>
                            <Form.Group id={restriction._id} controlId={restriction._id}>
                                <Form.Label><b>{restriction.restrictionQuestion}</b></Form.Label>
                                {this.state.optionsExpError && <p className="red">Please select your status of studying</p>}
                                <Form.Control defaultValue={-1} required as="select" onChange={(event) => { this.setState({ optionsExpError: false, formError: false }); this.changeAttribute(event.target.value, restriction._id) }}>
                                    <option disabled value={-1}> -- select an option -- </option>
                                    {restriction.restriction.options.map(option => {
                                        return <option key={option}>{option}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>)
            })
        }
    }

    renderConsultingExp() {
        let restrictions = this.state.restrictionDictionary.get("CONSULTINGEXP")
        if (restrictions) {
            return restrictions.map(restriction => {
                return (
                    <Row key={restriction}>
                        <Col>
                            <Form.Group id={restriction._id} controlId={restriction._id}>
                                <Form.Label><b>{restriction.restrictionQuestion}</b></Form.Label>
                                {this.state.consultingExpError && <p className="red">Please select your experience</p>}
                                <Form.Control defaultValue={-1} required as="select" onChange={(event) => { this.setState({ consultingExpError: false, formError: false }); this.changeAttribute(event.target.value, restriction._id) }}>
                                    <option disabled value={-1}> -- select an option -- </option>
                                    {restriction.restriction.options.map(option => {
                                        return <option key={option}>{option}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>)
            })
        }
    }

    random(seed) {
        var x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
    }

    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(this.random(this.props.match.params.studentHash) * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array
    }

    renderWorkingstyle() {
        let restrictions = this.state.restrictionDictionary.get("WORKINGSTYLE")
        if (restrictions) {
            let html = []
            html.push(<Row className="mb-2 mt-2"><Col><h3>Skills:</h3></Col></Row>)
            html.push(restrictions.map(restriction => {
                let options = this.shuffleArray(Object.keys(restriction.restriction.options))
                return (
                    <div key={restriction}>
                        <Row>
                            <Col>
                                <b>{restriction.restrictionQuestion} (<span className={`${this.state.newStudent.attributes.get(restriction._id).value !== null ? this.state.newStudent.attributes.get(restriction._id).value.length > 8 ? "red" : "" : ""}`}>{this.state.newStudent.attributes.get(restriction._id).value === null ? 0 : this.state.newStudent.attributes.get(restriction._id).value.length}</span>/8)</b>
                                {this.state.workingStyleError && <p className="red">Please select exactly 8 working styles</p>}
                            </Col>
                        </Row >
                        <Col>

                            {options.map((element) => {
                                return (
                                    <Row >
                                        <Form.Check
                                            key={element}
                                            type="checkbox"
                                            label={element}
                                            value={element}
                                            className="ml-2 mr-2"
                                            disabled={this.state.newStudent.attributes.get(restriction._id).value !== null && this.state.newStudent.attributes.get(restriction._id).value.length >= 8 && !this.state.newStudent.attributes.get(restriction._id).value.includes(element)}
                                            onChange={event => {
                                                this.setState({ workingStyleError: false, formError: false })
                                                let attributeValue = this.state.newStudent.attributes.get(restriction._id).value
                                                if (attributeValue === null) {
                                                    attributeValue = []
                                                }
                                                if (event.currentTarget.checked) {
                                                    attributeValue.push(element)
                                                } else {
                                                    attributeValue = attributeValue.filter(e => e !== element)
                                                }
                                                this.changeAttribute(attributeValue, restriction._id)
                                            }}
                                        />
                                    </Row >)
                            })}
                        </Col>
                    </div>)
            }))
            html.push(<hr />)
            return html
        }
    }


    renderTaskforce() {
        let restrictions = this.state.restrictionDictionary.get("TASKFORCE")
        let html = []
        if (restrictions) {
            restrictions.map((element, index) => {
                let rQuestion = element.restrictionQuestion.split("\n\n")
                let questionHTML = []
                for (let i = 0; i < rQuestion.length; i++) {
                    let tmp = rQuestion[i].split("\n")
                    for (let j = 0; j < tmp.length; j++) {
                        if (j === 0) {
                            questionHTML.push(<p><b>{tmp[j]}</b></p>)
                        } else {
                            questionHTML.push(<p>{tmp[j]}</p>)
                        }
                    }
                    questionHTML.push(<br />)
                }
                console.log(rQuestion)
                html.push(<Row className="mb-2 mt-2"><Col><h3>{element.restrictionName}</h3></Col></Row>)
                html.push(
                    <Row className="mt-4">
                        <Col className="allow-newline">
                            {questionHTML}
                        </Col>
                    </Row >)
                html.push(<Row className="mt-2">
                    <Col>
                        <b>Describe your preference from 1 (least preferable) to 5 (most preferable)</b>
                        {this.state.likertError && <p className="red">Please make sure to select a number for every option</p>}
                    </Col>
                </Row >)
                element.restriction.options.map((option, optionIndex) => {
                    html.push(
                        <Row className="mt-2">
                            {/* {(this.state.likertError && this.state.newStudent.attributes.get(restriction._id).value === null) ?
                                <Col className="mt-auto mb-auto red">
                                    {val.restrictionQuestion}
                                </Col> :
                                <Col className="mt-auto mb-auto">
                                    {val.restrictionQuestion}
                                </Col>} */}
                            <Col className="mt-auto mb-auto">
                                {option.projectName.substring(option.projectName.indexOf(" - ") + 3)}
                            </Col>
                            <Col>

                                <ButtonToolbar aria-label="Toolbar with button groups" className="float-right">
                                    <ButtonGroup className="mr-2" aria-label="First group" >
                                        <Button active={6 - this.state.newStudent.attributes.get(element._id).value[optionIndex].value === 1} variant="outline-primary" onClick={(event) => { this.setState({ likertError: false, formError: false }); this.changeOptionAttribute(6 - 1, element._id, optionIndex) }}>Low 1</Button>
                                        <Button active={6 - this.state.newStudent.attributes.get(element._id).value[optionIndex].value === 2} variant="outline-primary" onClick={(event) => { this.setState({ likertError: false, formError: false }); this.changeOptionAttribute(6 - 2, element._id, optionIndex) }}>2</Button>
                                        <Button active={6 - this.state.newStudent.attributes.get(element._id).value[optionIndex].value === 3} variant="outline-primary" onClick={(event) => { this.setState({ likertError: false, formError: false }); this.changeOptionAttribute(6 - 3, element._id, optionIndex) }}>3</Button>
                                        <Button active={6 - this.state.newStudent.attributes.get(element._id).value[optionIndex].value === 4} variant="outline-primary" onClick={(event) => { this.setState({ likertError: false, formError: false }); this.changeOptionAttribute(6 - 4, element._id, optionIndex) }}>4</Button>
                                        <Button active={6 - this.state.newStudent.attributes.get(element._id).value[optionIndex].value === 5} variant="outline-primary" onClick={(event) => { this.setState({ likertError: false, formError: false }); this.changeOptionAttribute(6 - 5, element._id, optionIndex) }}>5 High</Button>
                                    </ButtonGroup>
                                </ButtonToolbar>
                            </Col>
                        </Row >)
                    return null
                })
                return null
            })
        }
        html.push(<br />)
        restrictions = this.state.restrictionDictionary.get("COMMENT")
        if (restrictions) {
            restrictions.map((restriction, index) => {
                if (restriction.restrictionName === "Skill") {
                    html.push(
                        <Row className="mt-2">
                            <Col>
                                <Form.Group id={restriction._id} controlId={restriction._id}>
                                    <Form.Label><b>{restriction.restrictionQuestion}</b></Form.Label>
                                    <Form.Control as="textarea" rows={3} onChange={(event) => { this.changeAttribute(event.target.value, restriction._id) }} />
                                </Form.Group>
                            </Col>
                        </Row>
                    )
                }
                return null

            })
        }
        if (html.length !== 0) {
            html.push(<hr />)
        }
        return html
    }

    renderLikertPreference() {
        let restrictions = this.state.restrictionDictionary.get("LIKERTPREFERENCE")
        let html = []
        if (restrictions) {
            restrictions.map((element, index) => {
                html.push(<Row className="mb-2 mt-2"><Col><h3>{element.restrictionName}</h3></Col></Row>)
                html.push(
                    <Row className="mt-4">
                        <Col className="allow-newline">
                            {element.restrictionQuestion}
                        </Col>
                    </Row >)
                html.push(<Row className="mt-2">
                    <Col>
                        <b>Describe your preference from 1 (least preferable) to 5 (most preferable)</b>
                        {this.state.likertError && <p className="red">Please make sure to select a number for every option</p>}
                    </Col>
                </Row >)
                element.restriction.options.map((option, optionIndex) => {
                    html.push(
                        <Row className="mt-2">
                            <Col className="mt-auto mb-auto">
                                {option.projectName.substring(option.projectName.indexOf(" - ") + 3)}
                            </Col>
                            <Col>

                                <ButtonToolbar aria-label="Toolbar with button groups" className="float-right">
                                    <ButtonGroup className="mr-2" aria-label="First group" >
                                        <Button active={6 - this.state.newStudent.attributes.get(element._id).value[optionIndex].value === 1} variant="outline-primary" onClick={(event) => { this.setState({ likertError: false, formError: false }); this.changeOptionAttribute(6 - 1, element._id, optionIndex) }}>Low 1</Button>
                                        <Button active={6 - this.state.newStudent.attributes.get(element._id).value[optionIndex].value === 2} variant="outline-primary" onClick={(event) => { this.setState({ likertError: false, formError: false }); this.changeOptionAttribute(6 - 2, element._id, optionIndex) }}>2</Button>
                                        <Button active={6 - this.state.newStudent.attributes.get(element._id).value[optionIndex].value === 3} variant="outline-primary" onClick={(event) => { this.setState({ likertError: false, formError: false }); this.changeOptionAttribute(6 - 3, element._id, optionIndex) }}>3</Button>
                                        <Button active={6 - this.state.newStudent.attributes.get(element._id).value[optionIndex].value === 4} variant="outline-primary" onClick={(event) => { this.setState({ likertError: false, formError: false }); this.changeOptionAttribute(6 - 4, element._id, optionIndex) }}>4</Button>
                                        <Button active={6 - this.state.newStudent.attributes.get(element._id).value[optionIndex].value === 5} variant="outline-primary" onClick={(event) => { this.setState({ likertError: false, formError: false }); this.changeOptionAttribute(6 - 5, element._id, optionIndex) }}>5 High</Button>
                                    </ButtonGroup>
                                </ButtonToolbar>
                            </Col>
                        </Row >)
                    return null
                })
                html.push(<hr />)
                return null
            })
        }
        return html
    }

    renderLikert() {
        let restrictions = this.state.restrictionDictionary.get("LIKERT")
        if (restrictions) {
            restrictions = restrictions.reduce((result, restriction) => {
                let tmp = result.get(restriction.restriction.category[0])
                tmp = tmp === undefined ? new Array(restriction) : tmp.concat(restriction)
                result.set(restriction.restriction.category[0], tmp)
                return result
            }, new Map())
            let html = []
            html.push(<Row className="mt-2">
                <Col>
                    <b>Describe your skills from 1 (no relevant knowledge) to 5 (expert)</b>
                    {this.state.likertError && <p className="red">Please make sure to select a number for every skill</p>}
                </Col>
            </Row >)
            restrictions.forEach((val, key) => {
                html.push(
                    <Row className="mt-4">
                        <Col>
                            <b>{key}</b>
                        </Col>
                    </Row >)
                val.map(restriction => {
                    html.push(
                        <Row className="mt-2">
                            {(this.state.likertError && this.state.newStudent.attributes.get(restriction._id).value === null) ?
                                <Col className="mt-auto mb-auto red">
                                    {restriction.restrictionQuestion}
                                </Col> :
                                <Col className="mt-auto mb-auto">
                                    {restriction.restrictionQuestion}
                                </Col>}
                            <Col>
                                <ButtonToolbar aria-label="Toolbar with button groups" className="float-right">
                                    <ButtonGroup className="mr-2" aria-label="First group" >
                                        <Button active={this.state.newStudent.attributes.get(restriction._id).value === 1} variant="outline-primary" onClick={(event) => { this.setState({ likertError: false, formError: false }); this.changeAttribute(1, restriction._id) }}>Amateur 1</Button>
                                        <Button active={this.state.newStudent.attributes.get(restriction._id).value === 2} variant="outline-primary" onClick={(event) => { this.setState({ likertError: false, formError: false }); this.changeAttribute(2, restriction._id) }}>2</Button>
                                        <Button active={this.state.newStudent.attributes.get(restriction._id).value === 3} variant="outline-primary" onClick={(event) => { this.setState({ likertError: false, formError: false }); this.changeAttribute(3, restriction._id) }}>3</Button>
                                        <Button active={this.state.newStudent.attributes.get(restriction._id).value === 4} variant="outline-primary" onClick={(event) => { this.setState({ likertError: false, formError: false }); this.changeAttribute(4, restriction._id) }}>4</Button>
                                        <Button active={this.state.newStudent.attributes.get(restriction._id).value === 5} variant="outline-primary" onClick={(event) => { this.setState({ likertError: false, formError: false }); this.changeAttribute(5, restriction._id) }}>5 Expert</Button>
                                    </ButtonGroup>
                                </ButtonToolbar>
                            </Col>
                        </Row >)
                    return null
                })
            })
            html.push(<hr />)
            return html
        }
    }

    renderComments() {
        let restrictions = this.state.restrictionDictionary.get("COMMENT")
        if (restrictions) {
            let html = []
            html.push(<Row className="mb-2 mt-2"><Col><h3>Comments:</h3></Col></Row>)
            html.push(restrictions.map(restriction => {
                if (restriction.restrictionName !== "Skill") {
                    return (
                        <Row>
                            <Col>
                                <Form.Group id={restriction._id} controlId={restriction._id}>
                                    <Form.Label><b>{restriction.restrictionQuestion}</b></Form.Label>
                                    <Form.Control as="textarea" rows={3} onChange={(event) => { this.changeAttribute(event.target.value, restriction._id) }} />
                                </Form.Group>
                            </Col>
                        </Row>)
                }
                return null
            }))
            html.push(<hr />)
            return html
        }
    }

    changeEmail(value) {
        let newStudent = this.state.newStudent;
        newStudent.cdtmEmail = value;
        this.setState({ newStudent, cdtmEmailError: false, formError: false });
    }

    changeFirstName(value) {
        let newStudent = this.state.newStudent;
        newStudent.firstName = value;
        this.setState({ newStudent });
    }

    changeLastName(value) {
        let newStudent = this.state.newStudent;
        newStudent.lastName = value;
        this.setState({ newStudent });
    }

    changeAttribute(value, attributeId) {
        let newStudent = this.state.newStudent;
        let attribute = newStudent.attributes.get(attributeId)
        attribute.value = value
        newStudent.attributes.set(attributeId, attribute)
        this.setState({ newStudent })
    }

    changeOptionAttribute(value, attributeId, optionIndex) {
        let newStudent = this.state.newStudent;
        let attribute = newStudent.attributes.get(attributeId)
        attribute.value[optionIndex].value = value
        newStudent.attributes.set(attributeId, attribute)
        this.setState({ newStudent })
    }

    replacer(key, value) {
        if (value instanceof Map) {
            return {
                dataType: 'Map',
                value: Array.from(value.entries()), // or with spread: value: [...value]
            };
        } else {
            return value;
        }
    }

    reviver(key, value) {
        if (typeof value === 'object' && value !== null) {
            if (value.dataType === 'Map') {
                return new Map(value.value);
            }
        }
        return value;
    }

    submit() {
        let newStudent = this.state.newStudent
        let valid = true

        //eslint-disable-next-line
        if (newStudent.cdtmEmail.match("^.*(@cdtm\.de)$") === null || generateHash(this.state.newStudent.cdtmEmail).toString() !== this.props.match.params.studentHash) {
            valid = false
            this.setState({ cdtmEmailError: true })
        }

        newStudent.attributes.forEach((value, key) => {
            switch (value.restrictionSchema.restrictionType) {
                case "BACKGROUND":
                    if (value.value === null || value.value === -1) {
                        valid = false
                        this.setState({ backgroundError: true })
                    }
                    break;
                case "STUDYSTATUS":
                    if (value.value === null || value.value === -1) {
                        valid = false
                        this.setState({ studyStatusError: true })
                    }
                    break;
                case "CONSULTINGEXP":
                    if (value.value === null || value.value === -1) {
                        valid = false
                        this.setState({ consultingExpError: true })
                    }
                    break;
                case "OPTIONS":
                    if (value.value === null || value.value === -1) {
                        valid = false
                        this.setState({ optionsExpError: true })
                    }
                    break;
                case "WORKINGSTYLE":
                    if (value.value === null) {
                        valid = false
                        this.setState({ workingStyleError: true })
                    } else {
                        if (value.value.length !== 8) {
                            valid = false
                            this.setState({ workingStyleError: true })
                        }
                    }
                    break;
                case "LIKERT":
                    if (value.value === null || value.value === -1) {
                        valid = false
                        this.setState({ likertError: true })
                    }
                    break;
                case "COMMENT":
                    if (value.value === null) {
                        value.value = ""
                    }
                    break;
                case "LABEL":
                    if (value.value === null) {
                        value.value = []
                    }
                    break;
                default:
            }
        })
        newStudent.stagings = new Map()
        newStudent.stagings.set(this.props.match.params.id, newStudent.attributes)
        let tempStudent = { ...newStudent }
        delete tempStudent.attributes

        if (valid) {
            newStudent.attributes = Array.from(newStudent.attributes.values())
            fetch(process.env.REACT_APP_API_URL + '/open/studentToStaging/' + this.props.match.params.id, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(tempStudent, this.replacer)
            }).then((result) => {
                this.props.history.push('/surveyfinished');
            },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        } else {
            this.setState({ formError: true })
        }
    }


    render() {
        return (
            <div className="pb-5">
                <Col xs={12} sm={10} md={8} xl={6} className="ml-auto mr-auto mt-5 card">
                    <Row className="mb-4 mt-2"><Col><h2>Questionnaire</h2></Col></Row>
                    <Row className="mb-2 "><Col><h3>Personal Information:</h3></Col></Row>
                    <Form onSubmit={(event) => { event.preventDefault(); this.submit() }}>
                        <Row>
                            <Col>
                                <Form.Group controlId="cdtmEmail">
                                    <Form.Label><b>CDTM Email:</b></Form.Label>
                                    {this.state.cdtmEmailError && <p className="red">Please use your CDTM Email Address</p>}
                                    <Form.Control value={this.state.newStudent.cdtmEmail} required type="email" placeholder="CDTM Email" onChange={(event) => { this.changeEmail(event.target.value) }} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <Form.Group controlId="firstName">
                                    <Form.Label><b>First Name:</b></Form.Label>
                                    <Form.Control value={this.state.newStudent.firstName} required placeholder="First Name" onChange={(event) => { this.changeFirstName(event.target.value) }} />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group controlId="lastName">
                                    <Form.Label><b>Last Name:</b></Form.Label>
                                    <Form.Control value={this.state.newStudent.lastName} required placeholder="First Name" onChange={(event) => { this.changeLastName(event.target.value) }} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr />
                        {this.state.isLoaded && this.renderBackground()}
                        {this.state.isLoaded && this.renderStudyStatus()}
                        {this.state.isLoaded && this.renderConsultingExp()}
                        {this.state.isLoaded && this.renderOptions()}
                        <hr />
                        {this.state.isLoaded && this.renderWorkingstyle()}
                        {this.state.isLoaded && this.renderLikert()}
                        {this.state.isLoaded && this.renderTaskforce()}
                        {this.state.isLoaded && this.renderLikertPreference()}
                        {this.state.isLoaded && this.renderComments()}
                        {this.state.formError && <p className="red"><b>Please fill out all fields correctly. See warnings for more information.</b></p>}
                        <Button variant="primary" type="submit" className="mb-4 mt-2 float-right" >
                            Submit
                        </Button>
                    </Form>
                </Col>
            </div>
        );
    }
}

export { StudentSkillSurvey };