import React from "react";
import { Link, withRouter } from 'react-router-dom';
import { Navbar, Image, Col, Form, Button } from "react-bootstrap"
import { useKeycloak } from '@react-keycloak/web';

const Header = () => {
    const [keycloak] = useKeycloak();


    return (
        <div className="App">
            <Navbar bg="dark" variant="dark" fixed="top" className="navigation">
                {!(window.location.href.includes("/survey/skill/") || window.location.href.includes("/survey/preference/") || window.location.href.includes("/surveyfinished")) ? <>
                    <Navbar.Brand ><Link className="nav-link p-0" to="/"><Col xs={6} md={1}><Col xs={11}><Image src={require("../assets/4096-3411-max.png")} fluid /></Col></Col></Link></Navbar.Brand>
                    <Form inline>
                        {
                            keycloak && keycloak.authenticated &&
                            <Button variant="outline-light" onClick={() => keycloak.logout()} > Logout</Button>
                        }

                    </Form></> :
                    <Navbar.Brand ><Col xs={6} md={1}><Col xs={11}><Image src={require("../assets/4096-3411-max.png")} fluid /></Col></Col></Navbar.Brand>}
            </Navbar>
        </div>
    );

}

export default withRouter(Header);

/*<li><Link to="/About">About</Link></li>*/
/*<p>{this.props.location.pathname}</p>*/