import React from 'react';
import { useKeycloak } from '@react-keycloak/web';

const Authorizer = () => {
    const [keycloak] = useKeycloak();

    return (

        <div>
            {keycloak && !keycloak.authenticated &&
                <div>{keycloak.login()}</div>
            }
            {localStorage.setItem('matching-access-token', keycloak.token)}
        </div >
    )
}
export default Authorizer