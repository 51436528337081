import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "../theme.scss"

const Unauthorized = () => {
    const [keycloak] = useKeycloak();

    return (
        <div>
            <Row className="mb-5">
                <Col xs={12} sm={12} md={12} lg={10} xl={8} className="mt-2 ml-auto mr-auto">
                    <Card className="pl-3 mb-4">


                        {keycloak && !keycloak.authenticated && <div>
                            <Row className="mt-3 mb-2"><Col className="m-auto"><h3 className="pt-2 mb-2">Please login to use the application</h3></Col></Row>
                            <Row className="mt-3 mb-2"><Col className="justify-content-md-center"><Link to="/" className="linkWhite"><Button block variant="primary" className="mb-2" >Login</Button></Link></Col></Row></div>
                        }

                        {keycloak && keycloak.authenticated &&
                            <div>
                                <Row className="mt-3 mb-2"><Col className="m-auto"><h3 className="pt-2 mb-2">You might not be authorized to use this application</h3></Col></Row>
                                <Row className="mt-3 mb-2"><Col><Button variant="primary" block className="mb-2" onClick={() => keycloak.logout()}>Logout as ({
                                    keycloak.tokenParsed.preferred_username
                                })</Button></Col>
                                </Row>
                            </div>
                        }
                    </Card>
                </Col>
            </Row>
        </div >
    )
}
export default Unauthorized