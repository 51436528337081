import React, { Component } from "react";
import "bootstrap/js/src/collapse.js";
import { Card, Col, Row, Accordion, Button, InputGroup, FormControl, Image, Modal } from "react-bootstrap";
import '../index.css';
import './mpd.css';
import { renderBackground, RenderProjectPreferences, renderEmail, renderStudyStatus, renderWorkingStyle, studentStagingObjectToMap, renderCommentsSmall } from '../Student/student.commons';
import { renderBackgroundTag, renderLabelTag, renderStudyStatusTag, RenderTagInput, getTagRestriction, getLabels, renderConsultingExpTag, renderTechTag } from '../Student/student.tags';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { Drawer } from '../Drawer/drawer'
import StudentProfile from "../Student/student.profile";
import { checkStageAndIndex, getMaxRestriction, separateStages } from "../utilities/team.common";


class MPDstaging extends Component {
    constructor() {
        super();
        this.state = {
            isLoaded: false,
            availableFilter: [],
            minPeoplePerProject: [],
            maxPeoplePerProject: [],
            filterOptionsPerProject: [],
            staging: null,
            drawerOpen: false,
            drawerStudent: null,
            drawerStudentIndex: 0,
            labelMap: null,
            labelUsedMap: null,
            maxRestriction: null,
            separatedStages: null,
            waiting: false,
            error: ""
        };
        this.updateProjectLabels = this.updateProjectLabels.bind(this);
        this.getPreviousDrawerStudent = this.getPreviousDrawerStudent.bind(this);
        this.getNextDrawerStudent = this.getNextDrawerStudent.bind(this);
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_API_URL + "/stagingById/" + this.props.match.params.id, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    result.students = studentStagingObjectToMap(result.students)
                    this.setState({
                        availableFilter: result.restrictions,
                        staging: result
                    });
                    if (this.state.staging.stagingType.toUpperCase() === "TRENDSEMINAR" || this.state.staging.stagingType.toUpperCase() === "TASKFORCE & BUDDIES") {
                        let separatedStages = separateStages(this.state.staging.projects)
                        this.setState({ separatedStages: separatedStages })
                    }
                    let maxRestriction = getMaxRestriction(this.state.staging.students, this.props.match.params.id)
                    this.setState({ maxRestriction }, function () {
                        this.initializeForms();
                    })



                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    async initializeForms() {
        let minPeoplePerProject = [];
        let maxPeoplePerProject = [];
        let filterOptionsPerProject = [];
        for (let i = 0; i < this.state.staging.projects.length; i++) {
            let nrStudents = this.state.staging.students.length;
            let nrTeams = 0;
            if (this.state.separatedStages && (this.state.staging.stagingType.toUpperCase() === "TRENDSEMINAR" || this.state.staging.stagingType.toUpperCase() === "TASKFORCE & BUDDIES")) {
                nrTeams = this.state.separatedStages["teams"][checkStageAndIndex(i, this.state.separatedStages)['stageIndex']]
                minPeoplePerProject.push(Math.floor((nrStudents) / nrTeams));
                maxPeoplePerProject.push(Math.ceil(nrStudents / nrTeams));
            } else {
                nrTeams = this.state.staging.projects.length
                minPeoplePerProject.push(Math.floor((nrStudents) / nrTeams));
                maxPeoplePerProject.push(Math.ceil(nrStudents / nrTeams));
            }
            let filterOptions = [];
            for (let filterCounter = 0; filterCounter < this.state.availableFilter.length; filterCounter++) {
                switch (this.state.availableFilter[filterCounter].restrictionType) {
                    case 'DROPDOWN':
                    case 'BACKGROUND':
                    case 'CONSULTINGEXP':
                    case 'OPTIONS':
                        let valArray = [];
                        for (let optionIndex = 0; optionIndex < this.state.availableFilter[filterCounter].restriction.options.length; optionIndex++) {
                            if (this.state.staging.stagingType.toUpperCase() === "TRENDSEMINAR" && this.state.availableFilter[filterCounter].restrictionType === 'BACKGROUND') {
                                valArray.push(0)
                            } else {
                                if (this.state.availableFilter[filterCounter].restrictionName === "Tech") {
                                    valArray.push(Math.floor((this.state.maxRestriction && this.state.maxRestriction[this.state.availableFilter[filterCounter]._id] && this.state.maxRestriction[this.state.availableFilter[filterCounter]._id][this.state.availableFilter[filterCounter].restriction.options[optionIndex]] ? this.state.maxRestriction[this.state.availableFilter[filterCounter]._id][this.state.availableFilter[filterCounter].restriction.options[optionIndex]] : 0) / nrTeams))
                                } else {
                                    valArray.push(Math.min(Math.floor((this.state.maxRestriction && this.state.maxRestriction[this.state.availableFilter[filterCounter]._id] && this.state.maxRestriction[this.state.availableFilter[filterCounter]._id][this.state.availableFilter[filterCounter].restriction.options[optionIndex]] ? this.state.maxRestriction[this.state.availableFilter[filterCounter]._id][this.state.availableFilter[filterCounter].restriction.options[optionIndex]] : 0) / nrTeams), 1))
                                }
                            }
                        }
                        filterOptions[filterCounter] = ({ restriction: this.state.availableFilter[filterCounter], options: this.state.availableFilter[filterCounter].restriction.options, values: valArray });
                        break;
                    case 'LIKERT':
                        filterOptions[filterCounter] = ({ restriction: this.state.availableFilter[filterCounter], value: 0 });
                        break;
                    case 'LABEL':
                        let labelArray = new Array(this.state.availableFilter[filterCounter].restriction.options.length).fill(0);
                        filterOptions[filterCounter] = ({ restriction: this.state.availableFilter[filterCounter], options: this.state.availableFilter[filterCounter].restriction.options, values: labelArray });
                        break;
                    case 'PREFERENCE':
                    case 'LIKERTPREFERENCE':
                    case 'TASKFORCE':
                        filterOptions[filterCounter] = ({ restriction: this.state.availableFilter[filterCounter], options: null, values: null });
                        break;
                    default:
                        filterOptions[filterCounter] = ({ restriction: this.state.availableFilter[filterCounter], options: null, values: null });
                        break;
                }
            }
            filterOptionsPerProject.push(filterOptions)
        }
        let labelMap = getLabels(this.state.staging.students, this.props.match.params.id);
        let labelUsedMap = new Map(labelMap)
        this.setState({ minPeoplePerProject, filterOptionsPerProject, maxPeoplePerProject, labelMap, labelUsedMap, isLoaded: true });
    }

    requestMatching() {
        let staging = {};
        staging["_id"] = this.state.staging._id;
        staging["minPeoplePerProject"] = {};
        staging["minPeoplePerProject"] = this.state.minPeoplePerProject;
        staging["maxPeoplePerProject"] = {};
        staging["maxPeoplePerProject"] = this.state.maxPeoplePerProject;
        staging["filterOptionsPerProject"] = {};
        staging["filterOptionsPerProject"] = this.state.filterOptionsPerProject;/* 
        let totalPrerequesites = 0
        let totalPrerequesitesAdjusted = 0
        let teamsChangesLater = 0
        for (let i = 0; i < staging["filterOptionsPerProject"].length; i++) {
            for (let j = 0; j < staging["filterOptionsPerProject"][i].length; j++) {
                if (staging["filterOptionsPerProject"][i][j].values) {
                    totalPrerequesites += staging["filterOptionsPerProject"][i][j].values.reduce((res, e) => { return res + e }, 0)
                    if (this.state.separatedStages) {
                        totalPrerequesitesAdjusted += (staging["filterOptionsPerProject"][i][j].values.reduce((res, e) => { return res + e }, 0) ** checkStageAndIndex(i, this.state.separatedStages)['stageIndex'] ** checkStageAndIndex(i, this.state.separatedStages)['stageIndex'])
                        if (checkStageAndIndex(i, this.state.separatedStages)['stageIndex'] > 1) {
                            teamsChangesLater += staging["filterOptionsPerProject"][i][j].values.reduce((res, e) => { return res + e }, 0)
                        }
                    }
                }
            }
        } */
        this.setState({ waiting: true })
        fetch(process.env.REACT_APP_API_URL + '/matching', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
            },
            body: JSON.stringify(
                staging
            )
        }).then(res => {
            if (!res.ok) {
                return res.json().then(text => { throw new Error(text.error) })
            }
            else {
                return res.json();
            }
        }).then((result) => {
            if (!result) {
                throw new Error("An internal server occurred. A change in the matching parameters could fix this.")
            }
            this.props.history.push('/matching/' + result._id);
        }
        ).catch((err) => {
            this.setState({ waiting: false, error: err.message })
        })
    }

    getNextDrawerStudent() {
        let students = this.state.staging.students;
        let index = this.state.drawerStudentIndex + 1;
        if (index >= students.length) {
            index = 0;
        }
        this.setState({ drawerStudent: students[index], drawerStudentIndex: index });
    }

    getPreviousDrawerStudent() {
        let students = this.state.staging.students;
        let index = this.state.drawerStudentIndex - 1;
        if (index < 0) {
            index = students.length - 1;
        }
        this.setState({ drawerStudent: students[index], drawerStudentIndex: index });
    }

    renderProjectRestriction(filter, projectIndex, filterCounter) {
        switch (filter.restrictionType) {
            case 'PREFERENCE':
                return;

            case 'DROPDOWN': return (
                <div >
                    <hr className="ml-3 mr-3" />
                    <Row xs={12} className="ml-1 mr-1 mb-2" key={filter._id}>
                        <Col><b>{filter.restrictionName}:</b></Col>
                    </Row>
                    {this.renderDropdownRestriction(filter, projectIndex, filterCounter)}
                </div>
            );

            case 'BACKGROUND': return (
                <div key={filter._id}>
                    {this.state.staging.stagingType.toUpperCase() !== "TRENDSEMINAR" && <>
                        <hr className="ml-3 mr-3" />
                        <Row xs={12} className="ml-1 mr-1 mb-2">
                            <Col><b>{filter.restrictionName}:</b></Col>
                        </Row>
                        {this.renderDropdownRestriction(filter, projectIndex, filterCounter)}</>}
                </div>
            );
            case 'CONSULTINGEXP':
                return (
                    <div key={filter._id}>
                        <hr className="ml-3 mr-3" />
                        <Row xs={12} className="ml-1 mr-1 mb-2" >
                            <Col><b>{filter.restrictionName}:</b></Col>
                        </Row>
                        {this.renderConstOptions(filter, projectIndex, filterCounter)}
                    </div>
                );
            case 'OPTIONS':
                return (
                    <div key={filter._id}>
                        <hr className="ml-3 mr-3" />
                        <Row xs={12} className="ml-1 mr-1 mb-2" >
                            <Col><b>{filter.restrictionName}:</b></Col>
                        </Row>
                        {this.renderConstOptions(filter, projectIndex, filterCounter)}
                    </div>
                );
            case 'LIKERT': /* return (
                <div >
                    <hr className="ml-3 mr-3" />
                    <Row xs={12} className="ml-1 mr-1 mb-2" key={filter._id}>
                        <Col className="mt-auto mb-auto">{filter.restrictionName} Skill</Col>
                        {this.state.filterOptionsPerProject[projectIndex] != null && <Col>
                            <input
                                type="range"
                                list="tickmarks"
                                min="0"
                                max="100"
                                step="25"
                                value={this.state.filterOptionsPerProject[projectIndex][filterCounter].value}
                                onChange={
                                    e => {
                                        let filterOptionsPerProject = [...this.state.filterOptionsPerProject];
                                        filterOptionsPerProject[projectIndex][filterCounter].value = parseInt(e.target.value);
                                        this.setState({ filterOptionsPerProject });
                                    }} />
                            <datalist id="tickmarks">
                                <option value="0"></option>
                                <option value="25"></option>
                                <option value="50"></option>
                                <option value="75"></option>
                                <option value="100"></option>
                            </datalist>
                        </Col>}
                    </Row>
                </div>
            ) */

                break;
            case 'LABEL':
                return (
                    <div key={filter._id}>
                        <hr className="ml-3 mr-3" />
                        <Row xs={12} className="ml-1 mr-1 mb-2" >
                            <Col><b>{filter.restrictionName}:</b></Col>
                        </Row>
                        {this.renderLabels(filter, projectIndex, filterCounter)}
                    </div>
                );
            default:
        }
    }

    renderConstOptions(constRestriction, projectIndex, filterCounter) {
        let tempHtml = [];
        constRestriction.restriction.options.map((option, optionIndex) => {
            if (option !== "No Consulting Exp.") {
                tempHtml.push(
                    <Row xs={12} className="ml-1 mr-1 mb-2" key={option}>
                        <Col xs={7} className="mt-auto mb-auto">Min. Nr. {option} ({this.state.maxRestriction && this.state.maxRestriction[constRestriction._id] && this.state.maxRestriction[constRestriction._id][option] ? this.state.maxRestriction[constRestriction._id][option] : 0}):</Col>
                        <Col>
                            <InputGroup>
                                <FormControl
                                    disabled={this.state.staging.archived || this.state.waiting || !this.state.maxRestriction || !this.state.maxRestriction[constRestriction._id] || !this.state.maxRestriction[constRestriction._id][option]}
                                    value={this.state.filterOptionsPerProject[projectIndex][filterCounter].values[optionIndex]}
                                    onChange={e => {
                                        if (this.state.maxRestriction[constRestriction._id][option] < e.target.value) {
                                            return
                                        }
                                        let dropdownOptionsPerProject = [...this.state.filterOptionsPerProject];
                                        dropdownOptionsPerProject[projectIndex][filterCounter].values[optionIndex] = parseInt(e.target.value);
                                        this.setState({ dropdownOptionsPerProject });
                                    }}
                                    aria-label="Minimum"
                                    type="Number"
                                    min="0"
                                    max={this.state.maxRestriction && this.state.maxRestriction[constRestriction._id] && this.state.maxRestriction[constRestriction._id][option] ? this.state.maxRestriction[constRestriction._id][option] : 0}
                                />
                            </InputGroup>
                        </Col>
                    </Row >
                );
            }
            return null;
        }
        );
        return tempHtml;
    }

    renderLabels(labelRestriction, projectIndex, filterCounter) {
        if (labelRestriction.restriction.options.length === 0) {
            return (<Row xs={12} className="ml-1 mr-1 mb-2" key={projectIndex}>
                <Col className="mt-auto mb-auto">Labels will be created automatically when added to students</Col></Row>)
        }
        return labelRestriction.restriction.options.map((option, optionIndex) => {
            if (this.state.labelMap.has(option)) {
                return (
                    <Row xs={12} className="ml-1 mr-1 mb-2" key={option}>
                        <Col xs={7} className="mt-auto mb-auto">{option} ({this.state.labelUsedMap.get(option) + 1}/{this.state.labelMap.get(option) + 1}):</Col>
                        <Col>
                            <BootstrapSwitchButton
                                disabled={this.state.staging.archived || this.state.waiting}
                                checked={this.state.filterOptionsPerProject[projectIndex][filterCounter].values[optionIndex]}
                                onlabel='required'
                                offlabel='optional'
                                onChange={(checked) => {
                                    let labelOptionsPerProject = [...this.state.filterOptionsPerProject];
                                    labelOptionsPerProject[projectIndex][filterCounter].values[optionIndex] = checked;
                                    let labelUsedMap = this.state.labelUsedMap
                                    let change = checked ? (-1) : 1
                                    labelUsedMap.set(option, labelUsedMap.get(option) + change)
                                    this.setState({ dropdownOptionsPerProject: labelOptionsPerProject, labelUsedMap });
                                }}
                            />
                        </Col>
                    </Row >
                )
            } else {
                return null
            }
        })

    }

    renderDropdownRestriction(dropdownRestriction, projectIndex, filterCounter) {
        let tempHtml = [];
        dropdownRestriction.restriction.options.map((option, optionIndex) => {
            tempHtml.push(
                <Row xs={12} className="ml-1 mr-1 mb-2" key={option}>
                    <Col xs={7} className="mt-auto mb-auto">Min. Nr. {option} ({this.state.maxRestriction && this.state.maxRestriction[dropdownRestriction._id] && this.state.maxRestriction[dropdownRestriction._id][option] ? this.state.maxRestriction[dropdownRestriction._id][option] : 0}):</Col>
                    <Col>
                        <InputGroup>
                            <FormControl
                                disabled={this.state.staging.archived || this.state.waiting || !this.state.maxRestriction || !this.state.maxRestriction[dropdownRestriction._id] || !this.state.maxRestriction[dropdownRestriction._id][option]}
                                value={this.state.filterOptionsPerProject[projectIndex][filterCounter].values[optionIndex]}
                                onChange={e => {
                                    if (this.state.maxRestriction[dropdownRestriction._id][option] < e.target.value) {
                                        return
                                    }
                                    let dropdownOptionsPerProject = [...this.state.filterOptionsPerProject];
                                    dropdownOptionsPerProject[projectIndex][filterCounter].values[optionIndex] = parseInt(e.target.value);
                                    this.setState({ dropdownOptionsPerProject });
                                }}
                                aria-label="Minimum"
                                type="Number"
                                min="0"
                                max={this.state.maxRestriction && this.state.maxRestriction[dropdownRestriction._id] && this.state.maxRestriction[dropdownRestriction._id][option] ? this.state.maxRestriction[dropdownRestriction._id][option] : 0}
                            />
                        </InputGroup>
                    </Col>
                </Row >
            );
            return null;
        }
        );
        return tempHtml;
    }

    updateProjectLabels(updatedRestriction) {
        fetch(process.env.REACT_APP_API_URL + "/stagingById/" + this.props.match.params.id, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('matching-access-token')
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    result.students = studentStagingObjectToMap(result.students)
                    let new_drawer_student = result.students[this.state.drawerStudentIndex]
                    this.setState({
                        isLoaded: true,
                        availableFilter: result.restrictions,
                        staging: result,
                        drawerStudent: new_drawer_student
                    });

                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            ).then(() => {
                this.initializeForms();
            })
    }

    removeStageFromTeamName(projectIndex) {
        let project = this.state.staging.projects[projectIndex]
        if (this.state.separatedStages) {
            return project.projectName.substr(checkStageAndIndex(projectIndex, this.state.separatedStages)['stage'].length + 3, project.projectName.length)
        }
        return project.projectName
    }

    render() {
        return (
            <div>
                <Drawer open={this.state.drawerOpen} width={"50%"} onDrawerClose={() => this.setState({ drawerOpen: false })} onPrevious={() => this.getPreviousDrawerStudent()} onNext={() => this.getNextDrawerStudent()}>
                    <div className="incectedContent">{this.state.drawerStudent && <StudentProfile labelMap={this.state.labelMap} student={this.state.drawerStudent} updateProjectLabels={this.updateProjectLabels} stagingId={this.props.match.params.id} stagingType={this.state.staging.stagingType} />}</div>
                </Drawer>
                <div className="backgroundLightGray">{this.state.isLoaded && (<div>
                    <h2 className=" pt-2 mb-2 ml-3">{this.state.staging.stagingName} - Configure & Match</h2>
                    <Row className="m-2">
                        <Col xs={12} sm={6} md={5} lg={4} xl={3} className="mt-2">
                            <Card >
                                <h3 className=" mt-3 mb-3 ml-3">Students</h3>
                                <div className="studentList mb-2">
                                    {this.state.staging.students.map((student) =>
                                        <Col key={student._id} data={student}><Card className="mb-1"><Accordion>
                                            <Accordion.Toggle className="fullSize text-left blackFont" as={Button} variant="link" eventKey="0">
                                                <Row >
                                                    <Col xs={2} className="">
                                                        <Image src={student.profilePicture} roundedCircle fluid />
                                                    </Col>
                                                    <Col className="mt-auto mb-auto">
                                                        {student.firstName} {student.lastName} {this.state.staging.stagingType.toUpperCase() === "TRENDSEMINAR" ? renderTechTag(student, this.props.match.params.id) : renderBackgroundTag(student, this.props.match.params.id)}{renderStudyStatusTag(student, this.props.match.params.id)}{renderConsultingExpTag(student, this.props.match.params.id)}{renderLabelTag(student, this.props.match.params.id)}
                                                    </Col>
                                                </Row>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body> <div>
                                                    {renderEmail(student)}
                                                    {renderBackground(student, this.props.match.params.id)}
                                                    {renderStudyStatus(student, this.props.match.params.id)}
                                                    {renderCommentsSmall(student, this.props.match.params.id)}
                                                    {renderWorkingStyle(student, this.props.match.params.id)}
                                                    <RenderProjectPreferences student={student} stagingId={this.props.match.params.id} stagingType={this.state.staging.stagingType} />
                                                    <hr />
                                                    {(getTagRestriction(student, this.props.match.params.id) !== -1) ? <div><RenderTagInput student={student} stagingId={this.props.match.params.id} labelMap={this.state.labelMap} restrictionLabel={getTagRestriction(student, this.props.match.params.id)} updateProjectLabels={this.updateProjectLabels} />
                                                        <hr /></div> : null}
                                                    <Row className="mt-3">
                                                        <Col xs={10} className="ml-auto mr-auto">
                                                            <Button onClick={() => { this.setState({ drawerOpen: true, drawerStudent: student }) }} className="fullSize">
                                                                <b>Full Profile</b>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Accordion></Card>
                                        </Col>
                                    )}
                                </div>
                            </Card>
                        </Col>
                        <Col className="mt-2 projectList">
                            <Row>
                                {this.state.staging.projects.map((project, projectIndex) =>
                                    <>
                                        {this.state.separatedStages && (this.state.staging.stagingType.toUpperCase() === "TRENDSEMINAR" || this.state.staging.stagingType.toUpperCase() === "TASKFORCE & BUDDIES") && checkStageAndIndex(projectIndex, this.state.separatedStages)['teamIndexInStage'] === 0 ? <Col xs={12}><h5 className="mt-3">{checkStageAndIndex(projectIndex, this.state.separatedStages)['stage'].toUpperCase()}</h5></Col>
                                            : null}
                                        {this.state.separatedStages && this.state.staging.stagingType.toUpperCase() === "TRENDSEMINAR" && this.state.staging.stagingType.toUpperCase() === "TRENDSEMINAR" && checkStageAndIndex(projectIndex, this.state.separatedStages)['teamIndexInStage'] === 0 && checkStageAndIndex(projectIndex, this.state.separatedStages)['stageIndex'] > 1 && <Col xs={12}><p>Please be aware that if a lot of constraints are given for this stage, the matching might take a very long time. It is advised to not choose too many parameters.</p></Col>}
                                        <Col xs={12} sm={12} md={6} lg={6} xl={4} className="mb-4" key={project._id}>
                                            <Card>
                                                <h4 className=" mt-3 mb-3 ml-3">{this.removeStageFromTeamName(projectIndex)}</h4>
                                                <div>
                                                    <Row xs={12} className="ml-1 mr-1 mb-2">
                                                        <Col xs={7} className="mt-auto mb-auto">Min. Nr. People</Col>
                                                        <Col>

                                                            <InputGroup>
                                                                <FormControl
                                                                    disabled={this.state.staging.archived || this.state.waiting}
                                                                    value={this.state.minPeoplePerProject[projectIndex]}
                                                                    onChange={e => {
                                                                        let minPeoplePerProject = [...this.state.minPeoplePerProject];
                                                                        minPeoplePerProject[projectIndex] = parseInt(e.target.value);
                                                                        this.setState({ minPeoplePerProject });
                                                                    }}
                                                                    aria-label="Minimum"
                                                                    type="Number"
                                                                    min="0"
                                                                />
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row xs={12} className="ml-1 mr-1 mb-2">
                                                        <Col xs={7} className="mt-auto mb-auto">Max. Nr. People</Col>
                                                        <Col>
                                                            <InputGroup>
                                                                <FormControl
                                                                    disabled={this.state.staging.archived || this.state.waiting}
                                                                    value={this.state.maxPeoplePerProject[projectIndex]}
                                                                    onChange={e => {
                                                                        let maxPeoplePerProject = [...this.state.maxPeoplePerProject];
                                                                        maxPeoplePerProject[projectIndex] = parseInt(e.target.value);
                                                                        this.setState({ maxPeoplePerProject });
                                                                    }}
                                                                    aria-label="Maximum"
                                                                    type="Number"
                                                                    min="0"
                                                                />
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <Accordion>
                                                    <Accordion.Toggle className="fullSize blackFont" as={Button} variant="link" eventKey="0">
                                                        Toggle more Filter
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="0">
                                                        <div>
                                                            {this.state.availableFilter.map((filter, filterCounter) =>
                                                                this.renderProjectRestriction(filter, projectIndex, filterCounter)
                                                            )}
                                                        </div>
                                                    </Accordion.Collapse>
                                                </Accordion>
                                            </Card>
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </Col>
                    </Row>
                    <Button disabled={this.state.staging.archived || this.state.waiting} onClick={() => { this.requestMatching(); }} className="float">
                        <b className="my-float">Match</b>
                    </Button>
                    <Modal show={this.state.error !== ""} onHide={(e) => { this.setState({ error: "" }) }}>
                        <Modal.Header closeButton>
                            <Modal.Title>An error occurred</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{this.state.error}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={(e) => { this.setState({ error: "" }) }}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>)}</div>
            </div>
        );
    }
}

export default MPDstaging;
